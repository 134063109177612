import {
  MdSupport,
  MdPeople,
  MdInsights,
  MdChat,
  MdSecurity,
  MdGroup,
  MdSettings,
} from "react-icons/md";
export const bankContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Empower your financial services with a secure, human-like Gen AI solution that offers 24/7 personalized support. Engage customers responsibly across 40+ channels and in 140+ languages, providing fast, scalable, and reliable experiences that redefine customer satisfaction and trust. ",
    id: "1",
    imageURL: "/images/solutions/bank/banner.png",
    solutionType: "byIndustry",
    background: "/images/solutions/healthcare/banner.jpg",
    sectionTile: "",
    title: "Transform BFSI with fast, secure, and responsible AI",
  },

  stats: {
    title: "Research Insights on AI in BFSI",
    description:
      "Key Metrics Shaping AI-Driven Innovation in Financial Services",
    content: [
      {
        id: "1",
        title: "of BFSI respondents boosted AI/ML investments recently.",
        count: 82,
        source: "BFSI AI-ML Investment Report",
      },
      {
        id: "2",
        title: "of BFSI institutions plan future AI/ML investments.",
        count: 87,
        source: " AI-ML Investment Survey",
      },
      {
        id: "3",
        title: "billion expected BFSI Generative AI market by 2032.",
        count: 12.34,
        source: "Global Generative AI Market Report",
        countType: "Bn+",
      },
      {
        id: "4",
        title: "BFSI firms improved efficiency, reduced costs with AI.",
        count: 73,
        source: "BFSI AI Adoption Impact Report ",
      },
    ],
  },
  whyChooseUs: {
    title: "Pillars of iNextLabs Gen AI for BFSI",
    tagline:
      "Our productized Gen AI solution for Banking and Financial Services is built on four powerful pillars, offering scalable, secure, and innovative AI capabilities that elevate customer experience and streamline operations: ",
    content: [
      {
        id: "1",
        title: "Intelligent conversational assistants ",
      },
      {
        id: "2",
        title: "Document processing system",
      },
      {
        id: "3",
        title: "Smart business innovation and automation",
      },
      {
        id: "4",
        title: "Automated claim processing ",
      },
    ],
    features: [
      {
        className: "features-details-card-add",
        id: "1",
        imageURL: "/images/solutions/bank/automated-claim-processing.jpg",
      },
      {
        className: "features-details-card-even",
        id: "2",
        imageURL: "/images/solutions/bank/document-processing-system.jpg",
      },
      {
        className: "features-details-card-add",
        id: "3",
        imageURL: "/images/solutions/bank/Intelligent-conversational-assistants.jpg",
      },
      {
        className: "features-details-card-even",
        id: "4",
        imageURL: "/images/solutions/bank/innovation-and-automation.jpg",
      },
    ],
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/solutions/bank/customer-engagement.png",
      pointer: "/images/icons/pointer.svg",
      title: "Enhance Customer Engagement with inFlow Assistants ",
      mainDescription:
        "Our Gen AI-powered Assistants deliver human-like engagement across chat, voice, and avatar channels, transforming customer interactions with ease. They handle inquiries, assist with processes, and provide personalized recommendations while ensuring compliance. By automating routine tasks like data entry, these inFlow Assistants help financial institutions reduce errors and costs, empowering teams to focus on high-value work. ",
    },
    {
      id: "2",
      model: "model1",
      imageURL: "/images/solutions/bank/employee-efficiency.png",
      pointer: "/images/icons/pointer.svg",
      title: "Boost Operational Efficiency with AI Support",
      mainDescription:
        "Our Gen AI solution empowers the BFSI sector by automating repetitive tasks, minimizing manual errors, and streamlining critical processes across departments. Our inFlow Assistants enable teams to focus on high-impact initiatives, enhancing both productivity and operational efficiency. ",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/solutions/bank/document-management.png",
      pointer: "/images/icons/pointer.svg",
      title: "Optimize Document Management with Smart Analysis",
      mainDescription:
        "Streamline your document handling with our Generative AI solution, which extracts and processes critical data from large volumes of paperwork. This innovative approach minimizes manual review times and enhances data accuracy, accelerating decision-making.  ",
    },
    {
      id: "4",
      model: "model1",
      imageURL: "/images/solutions/bank/risk-management.png",
      pointer: "/images/icons/pointer.svg",
      title: "Strengthen Your Risk Management Framework",
      mainDescription:
        "Enhance your risk management strategies with advanced data analytics powered by our Generative AI solution. This tool detects suspicious activities in real time, helping financial institutions proactively mitigate fraud risks and comply with regulatory requirements. ",
    },
    {
      id: "5",
      model: "model1",
      imageURL: "/images/solutions/bank/kyc-automation.png",
      pointer: "/images/icons/pointer.svg",
      title: "Streamline KYC Processes with Automation",
      mainDescription:
        "Simplify Know Your Customer (KYC) procedures using our Generative AI solution, which automates data verification and analysis. By quickly processing large datasets, our solution reduces onboarding time and increases accuracy, ensuring compliance with anti-money laundering regulations.  ",
    },
  ],

  assistantSolutions: {
    title: "Introducing the iNextLabs Generative inFlow Assistants",
    tagline:
      "Our inFlow Assistants facilitates authentic customer interactions, combining industry expertise with advanced technology to shape the future of the BFSI sector ",
    content: [
      {
        id: "1",
        title: "Voice Assistant",
        tagline:
          "Offers 24/7 concierge banking support, answering FAQs, processing transactions, and verifying identities, with intelligent call routing to agents for personalized service.",
        imageURL: "/images/solutions/bank/voice-assistant.jpg", // imageURL representing
      },
      {
        id: "2",
        title: "Knowledge Assistant",
        tagline:
          "he Knowledge Assistant leverages generative AI to provide instant answers, streamline processes, reduce onboarding time, and improve team performance.",
        imageURL: "/images/solutions/bank/knowledge-assistant.jpg", // imageURL representing security
      },
      {
        id: "3",
        title: "Avatar Assistant",
        tagline:
          "Provides a visually engaging, 24/7 conversational interface, automating customer service and seamlessly integrating with existing systems for efficient operations. ",
        imageURL: "/images/solutions/bank/avatar-assistant.jpg", // Icon representing cost efficiency
      },
    ],
  },
  ValueProposition: {
    title: "Why You Choose Us: It's More Than Just These Reasons ",
    // tagline: "Enhance Your BFSI Business with Our Tailored Solutions",
    content: [
      {
        id: "1",
        title: "24/7 Customer Engagement",
        tagline: "Round-the-clock service for quick responses.",
        icon: <MdSupport size={30} />,
      },
      {
        id: "2",
        title: "Hyper-Personalized Financial Services",
        tagline: "Tailored recommendations boost engagement and trust.",
        icon: <MdPeople size={30} />,
      },
      {
        id: "3",
        title: "Deep AI-Powered Insights",
        tagline: "Actionable insights align offerings with customer needs.",
        icon: <MdInsights size={30} />,
      },
      {
        id: "4",
        title: "Seamless Integration with Legacy Systems",
        tagline: "Smooth integration for faster AI adoption.",
        icon: <MdSettings size={30} />,
      },
      {
        id: "5",
        title: "Omnichannel Communication",
        tagline: "Consistent customer experience across all platforms.",
        icon: <MdChat size={30} />,
      },
      {
        id: "6",
        title: "Compliance and Security",
        tagline: "Adherence to regulations while protecting data.",
        icon: <MdSecurity size={30} />,
      },
      {
        id: "7",
        title: "Empowering Human Agents",
        tagline: "Freeing agents to enhance customer relationships.",
        icon: <MdGroup size={30} />,
      },
    ],
  },
  redirectPage: {
    imageURL: "/images/avatar-assistant/avatars.png",
    title:
      "Discover How the AI Avatar Assistant is Revolutionizing Customer Engagement for Banks",
    tagline:
      "Experience a transformative shift in customer interactions with our AI Avatar Assistant for a leading Malaysian bank. This innovative solution provides personalized engagement, and 24/7 support multilingual support, setting new standards for customer satisfaction.",
    redirectURL: "/ai-avatar-assistant",
  },
  FAQ: [
    {
      id: "1",
      question:
        "What is Generative AI, and how does it differ from traditional AI?",
      answer:
        "Generative AI refers to algorithms that can create new content or data based on existing information. Unlike traditional AI, which primarily analyzes and processes data, Generative AI can generate text, images, or even predictive models, enabling more dynamic interactions and personalized experiences in banking, such as tailored financial advice and automated customer communications.",
    },
    {
      id: "2",
      question:
        "How can Generative AI enhance customer experience in the BFSI sector?",
      answer:
        "Generative AI can enhance customer experience by providing personalized interactions, automating routine inquiries, and offering real-time support through chatbots and virtual assistants. This technology allows banks to deliver instant, relevant information, improve onboarding processes, and tailor financial product recommendations based on individual customer preferences and behaviors.",
    },
    {
      id: "3",
      question:
        "Is Generative AI secure and compliant with industry regulations?",
      answer:
        "Yes, when implemented correctly, Generative AI can be secure and compliant. Financial institutions must ensure that AI solutions adhere to data protection regulations, such as GDPR and PCI DSS. This involves using secure data handling practices, conducting regular audits, and employing transparency in AI decision-making processes to protect sensitive customer information.",
    },
    {
      id: "4",
      question: "Will Generative AI replace human agents in the BFSI sector?",
      answer:
        "Generative AI is designed to augment human capabilities rather than replace them. While it automates routine tasks and improves efficiency, human agents remain essential for complex customer interactions, relationship-building, and providing personalized financial advice. By automating repetitive tasks, AI allows human agents to focus on higher-value services and foster deeper customer relationships.",
    },
  ],
  meta: {
    title:
      "Conversational AI for Banking | Generative AI Solutions for BFSI Sector",
    description:
      "Transform the BFSI sector with iNextLabs' secure AI for banking and generative AI for finance. Enhance customer service and automate operations efficiently",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
