import { FaHandsHelping } from "react-icons/fa";
import { MdInsights, MdIntegrationInstructions, MdOutlineSecurity } from "react-icons/md";
export const analyzerContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Seamlessly process documents, images, audio, and video to streamline workflows, minimize errors, and make informed decisions. Whether in legal, healthcare, or government sectors, experience advanced data analysis with unmatched accuracy and speed to propel your business forward.",
    imageURL: "/images/products/analyzer/banner.jpg",
    sectionTile: "",
    title: "AI that works with your data for business efficiency",
  },

  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/products/analyzer/document-analysis.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Comprehensive document analysis",
      mainDescription:
        "Automate your data handling with ease using our advanced solutions. Streamline processing by automating data extraction, reducing manual input, and minimizing errors. Enhance contract compliance by analyzing documents for key terms and conditions, ensuring you meet essential requirements. Additionally, transform lengthy reports and articles into concise summaries with automated content summarization, making it easier to extract crucial information quickly.",
    },
    {
      id: "2",
      model: "model1",
      imageURL: "/images/products/analyzer/text-and-image-processing.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Advanced text and image processing",
      mainDescription:
        "Harness the power of advanced text and image processing to elevate your data management. Conduct sentiment analysis on customer feedback to gauge satisfaction and categorize extensive text data for research purposes. Enhance image quality by detecting issues such as blurriness or unwanted visual effects, and streamline your documentation process by converting handwritten forms and scanned documents into searchable formats.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/products/analyzer/media-analyzer.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "AI-Powered media analyzer",
      mainDescription:
        "Transform how you handle media with our AI-powered solutions. Efficiently convert speech to text and text to speech to meet various needs and make content more accessible through audio translation and transcription. Gain deeper insights by analyzing user behavior across different platforms, optimizing experiences, and informing strategic decisions with comprehensive behavior analysis.",
    },
  ],

  whyChooseUs: {
    title: "Why choose us",
    tagline: "It's more than just these reasons",
    content: [
      {
        id: "1",
        title: "Unmatched security",
        tagline:
          "We ensure robust encryption, compliance, and continuous data monitoring.",
        icon: <MdOutlineSecurity  size={20} />,
      },
      {
        id: "2",
        title: "Seamless integration",
        tagline:
          "Easily integrates with IT systems, offering quick deployment and simplicity.",
        icon: <MdIntegrationInstructions size={20} />,
      },
      {
        id: "3",
        title: "Data-Driven insights",
        tagline:
          "Our AI solutions provide real-time analytics for informed, data-driven decisions.",
        icon: <MdInsights size={20} />,
      },
      {
        id: "4",
        title: "Comprehensive support",
        tagline:
          "Enhance communication with humanized, multi-channel, multilingual support for teams.",
        icon: <FaHandsHelping size={20} />,
      },
    ],
  },

  FAQ: [
    {
      id: "1",
      question:
        "How can AI-driven insights improve decision-making in my organization?",
      answer:
        "AI-driven insights analyze vast amounts of data in real-time, transforming complex information into actionable visuals. This allows decision-makers to quickly identify trends, evaluate options, and make informed choices with greater accuracy. By leveraging AI, organizations can minimize the risk of human error, optimize strategies, and respond more effectively to changing market conditions and operational challenges.",
    },
    {
      id: "2",
      question: "What types of data can inFlow insights analyze?",
      answer:
        "inFlow insights can analyze a wide range of data, including customer interactions, employee performance, operational metrics, and financial records. By integrating data from multiple sources, AI provides a holistic view of your business, uncovering patterns and trends that might be missed with manual analysis. This comprehensive approach ensures that all aspects of your organization are considered when making strategic decisions.",
    },
    {
      id: "3",
      question: "How does AI enhance performance tracking?",
      answer:
        "AI enhances performance tracking by continuously monitoring key performance indicators (KPIs) across various departments and functions. It automatically identifies deviations from expected outcomes and provides real-time alerts. This allows managers to address issues immediately, ensuring that goals are met and resources are optimally allocated. AI also offers predictive insights, helping organizations forecast future performance and adjust strategies proactively.",
    },
    {
      id: "4",
      question: "Can inFlow insights help with real-time monitoring?",
      answer:
        "Yes, inFlow insights are designed to provide real-time monitoring of critical metrics. By continuously analyzing incoming data, AI systems deliver instant updates on important developments, enabling organizations to react quickly to emerging trends or potential issues. This level of responsiveness ensures that businesses can maintain operational efficiency, improve decision-making, and stay ahead of the competition.",
    },
  ],
  stats: {
    title: "inFlow analyzers that turn data into strategic value",
    description: "Cut wait times, streamline tasks, and enhance response speed",
    content: [
      {
        id: "1",
        title: "Boost in GDP",
        count: 26,
      },
      {
        id: "2",
        title: "Improvement in customer relations",
        count: 64,
      },
      {
        id: "3",
        title: "Potential growth",
        count: 54,
      },
      {
        id: "4",
        title: "Cost reduction",
        count: 30,
      },
    ],
  },
  meta: {
    title: "AI for Documents | inFlow Analyzer for Business Efficiency | iNextLabs",
    description:
      "iNextLabs' inFlow Analyzer, the ultimate AI for documents. Enhance workflows by processing documents, images, and audio for accurate data analysis.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
