import React from "react";
import Header from "../header/Header";
import Filler from "../header/Filler";
import { resourcesContent } from "./resourcesContent";
import { useParams } from "react-router-dom";
import ResourceBanner from "./ResourceBanner";
import ResourceDetailsItems from "./ResourceDetailsItems";
import Footer from "../footer/Footer";

const WebinarDetails = () => {
  const { id } = useParams(); // Use useParams to get the route parameter
  const selectedWebinarUrl = id;
  const webinarItems = resourcesContent.resourceItems.filter(
    (resource) => resource.type === "Webinar"
  );
  console.log(webinarItems);

  const selectedWebinar = webinarItems.find(
    (webinar) => webinar.url === selectedWebinarUrl
  );
  return (
    <>
      <Header />
      <Filler />
      <ResourceBanner content={selectedWebinar} />
      <ResourceDetailsItems content={selectedWebinar} />
      <Footer />
    </>
  );
};

export default WebinarDetails;
