export const webinarContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/webinar-registration",
    },
    description:
      "Discover AI's impact through our targeted webinar series for decision-makers. Gain strategic insights into using AI to boost efficiency, customer engagement, and competitiveness. Join experts as they explore case studies, reveal best practices, and provide roadmaps for integrating AI technologies into your business landscape.",
    id: "webinar",
    imageURL: "/images/banner-images/pexels-polina-zimmerman-3747446.jpg",
    title: "Master Business Innovation Through Our Webinars",
  },
  webinarItems: [
  ],
  meta: {
    title: "Latest Webinars on AI across industries",
    description:
      "Join our AI webinars at iNextLabs to gain insights from industry experts. Stay updated with the latest trends and advancements in artificial intelligence.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/webinar.png",
    type: "website",
  },
};
