import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import NewsLetter from "../newsletter/NewsLetter";
import SignUpModal from "../modal/SignUpModal";
import { Button } from "@material-tailwind/react";
import { freeTrailClickFunction } from "../../app-insights/freeTrailClickFunction";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { FaLocationDot } from "react-icons/fa6";
import { MdAccessTime, MdOutlineAccessTimeFilled } from "react-icons/md";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: false,
    });
  }, []);
  return (
    <>
      <div className="footer-outer-container">
        <div
          className="footer-upper-info-card"
          data-aos="fade-down"
          data-aos-duration="2000"
        >
          <Card className="footer-upper-card">
            <div className="footer-upper-info-container">
              <div className="footer-upper-info-left">
                <div className="footer-upper-info-content">
                  <h2 className="footer-upper-info-container-title">
                    The Value Providers with Purpose
                  </h2>
                  <p className="footer-upper-info-container-description">
                    Experience the Commitment of a Trusted and Promising
                    Generative AI Platform
                  </p>
                </div>
                <div className="footer-upper-info-container-button">
                  <Button
                    className="button"
                    onClick={() => {
                      setIsOpen(true);
                      freeTrailClickFunction();
                    }}
                  >
                    Start Your Free Trial
                  </Button>
                </div>
              </div>
              <div className="footer-upper-info-right">
                <img
                  src="/images/general/footer-milestone.svg"
                  alt="footer upper image"
                  id="footer-upper-image"
                />
              </div>
            </div>
          </Card>
        </div>
        <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />

        <div className="footer-inner-container">
          <div className="footer-details-container">
            <div className="footer-company-info-container">
              <div className="footer-company-info-logo">
                <img
                  src="/images/general/inextlabs-logo-footer.png"
                  width={200}
                  alt="inextlabs footer logo"
                />
              </div>
              <div className="footer-company-info-description">
                We are a Cloud and AI-focused innovative company with a mission
                to bring the power of artificial intelligence to benefit every
                business and individual
              </div>

              <div className="footer-company-info-location">
                <h4 className="font-bold flex gap-2 items-center">
                  <HiBuildingOffice2 />
                  Head Office
                </h4>
                <p className="ml-4">
                  1 Coleman Street, #10-06 The Adelphi, Singapore 179803
                </p>
                <h4 className="font-bold flex gap-2 items-center">
                  <FaLocationDot /> Other Locations
                </h4>
                <p className="ml-4">India | Malaysia | USA</p>
              </div>
              <div className="footer-company-info-contact">
                <div className="footer-company-info-contact-title">Contact</div>
                <div className="footer-company-info-contact-social-media">
                  <Link to="https://www.facebook.com/iNextLabs" target="_blank">
                    <img
                      src="/images/icons/facebook.svg"
                      alt="facebook"
                      className="footer-social-media-images"
                      width={28}
                    />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/inextlabs"
                    target="_blank"
                  >
                    <img
                      src="/images/icons/linkedin.svg"
                      alt="linkedin"
                      className="footer-social-media-images"
                      width={28}
                    />
                  </Link>

                  <Link to="https://twitter.com/inextlabs" target="_blank">
                    <img
                      src="/images/icons/twitter.svg"
                      alt="twitter"
                      className="footer-social-media-images"
                      width={27}
                    />
                  </Link>
                  <Link
                    to="https://www.youtube.com/channel/UCJDvWr__3VODxszufzBUHcw"
                    target="_blank"
                  >
                    <img
                      src="/images/icons/youtube.svg"
                      alt="youtube"
                      className="footer-social-media-images"
                      width={27}
                    />
                  </Link>
                  <Link
                    to="https://www.instagram.com/inextlabs/"
                    target="_blank"
                  >
                    <img
                      src="/images/icons/instagram.svg"
                      alt="instagram"
                      className="footer-social-media-images"
                      width={24}
                    />
                  </Link>
                </div>

                <div className="footer-company-info-contact-number">
                  <img src="/images/icons/phone.svg" alt="phone" width={14} />
                  +6592727331
                </div>
                <div className="footer-company-info-contact-mail">
                  <Link to="#">
                    <img src="/images/icons/mail.svg" alt="mail" width={14} />
                    info@inextlabs.com
                  </Link>
                </div>
              </div>
              <div className="footer-company-info-support">
                <div className="footer-company-info-support-title">
                  For Support
                </div>

                <div className="footer-company-info-support-mail">
                  <Link to="">
                    <img src="/images/icons/mail.svg" alt="mail" width={14} />
                    support@inextlabs.com
                  </Link>
                </div>
                <div
                  style={{
                    color: "#fff",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  className="footer-company-info-support-time"
                >
                  <MdOutlineAccessTimeFilled size={19} />
                  <p style={{ margin: 0 }}>
                    Monday to Friday, 9:00 AM to 6:00 PM <br />{" "}
                    <span>(local time except public holidays)</span>
                  </p>
                </div>
              </div>
              <NewsLetter />
            </div>

            <div className="footer-menu-container">
              <div>
                <div className="footer-menu-container-menuTitle">
                  SOLUTIONS - By Industry
                </div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/healthcare"
                  >
                    Healthcare
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/bank"
                  >
                    BFSI
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/restaurant"
                  >
                    Food & Beverage
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/retail"
                  >
                    Retail
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/other-industries"
                  >
                    Other Industries
                  </Link>
                </p>
              </div>

              <div>
                <div className="footer-menu-container-menuTitle">
                  SOLUTIONS - By Function
                </div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/sales-and-marketing"
                  >
                    Sales & Lead Generation
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/customer-support-automation"
                  >
                    Customer Support
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/human-resources"
                  >
                    Human Resources
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/information-technology"
                  >
                    Information Technology
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/procurement"
                  >
                    Procurement
                  </Link>
                </p>
              </div>
              <div>
                <div className="footer-menu-container-menuTitle">COMPANY</div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/about-us"
                  >
                    About Us
                  </Link>
                </p>
                <p>
                  <Link className="footer-menu-container-menuitem" to="/career">
                    Career
                  </Link>
                </p>
                <p>
                  <Link className="footer-menu-container-menuitem" to="/news">
                    Event & News
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/partners"
                  >
                    Partner with Us
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/corporate-responsibility"
                  >
                    Corporate Responsibility
                  </Link>
                </p>
              </div>
              <div>
                <div className="footer-menu-container-menuTitle">PRODUCTS</div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/ai-assistants"
                  >
                    inFlow Assistants
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/ai-analyzer"
                  >
                    inFlow Analyzer
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/ai-insights"
                  >
                    inFlow Insights
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/ai-insights"
                  >
                    inFlow Agent Assist
                  </Link>
                </p>
              </div>
              <div>
                <div className="footer-menu-container-menuTitle">
                  PRODUCTS - By Features
                </div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/foundation-model"
                  >
                    Foundation Model
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/security"
                  >
                    Security
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/data-governance"
                  >
                    Data Governance
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/omnichannel"
                  >
                    Omnichannel
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/integrations"
                  >
                    Integration
                  </Link>
                </p>
              </div>

              <div>
                <div className="footer-menu-container-menuTitle">RESOURCES</div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/resources/blog"
                  >
                    Blogs
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/resources/casestudy"
                  >
                    Case Studies
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/resources/library"
                  >
                    Library
                  </Link>
                </p>
              </div>
              <div>
                <div className="footer-menu-container-menuTitle">
                  TERMS & POLICIES
                </div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/terms-of-services"
                  >
                    Terms of Use & Service
                  </Link>
                </p>

                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/privacy-policy"
                  >
                    Privacy Policy
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="footer-copyrights-container">
            Copyright © 2024 iNextLabs - All Rights Reserved
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
