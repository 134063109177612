import React from "react";
import InnerPageValuePropsItem from "./InnerPageValuePropsItem";
const InnerPageValueProps = (props) => {
  const valuePropContent = props.content;
  return (
    <>
      {valuePropContent && (
        <div
          className="inner-value-props-main-container"
          style={{ overflowX: "hidden", overflowY: "hidden" }}
        >
          <div className="innerContainer">
            {valuePropContent.map((valueProp, index) => (
              <InnerPageValuePropsItem
                layout={index % 2 === 0 ? "Image-Content" : "Content-Image"}
                key={valueProp.id}
                content={valueProp}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default InnerPageValueProps;
