import React from "react";
import Filler from "../../header/Filler";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import { restaurentContent } from "./restaurentContent";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import Meta from "../../Meta";
import SolutionBanner from "../banner/SolutionBanner";
import CustomerStories from "../customer-stories/CustomerStories";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProposition from "../../value-proposition/ValueProposition";
import AccordionSection from "../../accordion/Accordion";
import PromoVideo from "../../promo-video/PromoVideo";
const Restaurent = () => {
  return (
    <>
      <Meta
        title={restaurentContent.meta.title}
        description={restaurentContent.meta.description}
        image={restaurentContent.meta.image}
        locale={restaurentContent.meta.locale}
        siteName={restaurentContent.meta.siteName}
        type={restaurentContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={restaurentContent.banner} />
      <PromoVideo videoURL="https://inextlabswebassets.blob.core.windows.net/video/F&B Compressed.mp4" />
      <CounterStats content={restaurentContent.stats} />
      <InnerPageValueProps content={restaurentContent.valueProps} />
      <ValueProposition content={restaurentContent.whyChooseUs} />
      <CustomerStories />
      {/* <WebinarSection /> */}
      <AccordionSection content={restaurentContent.FAQ} />
      <Footer />
    </>
  );
};

export default Restaurent;
