import React from "react";
import Meta from "../../Meta";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import ProductsBanner from "../banner/ProductsBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProps from "../../swimlinesection/ValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import AccordionSection from "../../accordion/Accordion";
import Footer from "../../footer/Footer";
import { dataGovernanceContent } from "./dataGovernanceContent";

const DataGovernance = () => {
  return (
    <div>
      <Meta
        title={dataGovernanceContent.meta.title}
        description={dataGovernanceContent.meta.description}
        image={dataGovernanceContent.meta.image}
        locale={dataGovernanceContent.meta.locale}
        siteName={dataGovernanceContent.meta.siteName}
        type={dataGovernanceContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <ProductsBanner content={dataGovernanceContent.banner} />
      <CounterStats content={dataGovernanceContent.stats} />
      <ValueProps content={dataGovernanceContent.valueProps} />
      <ValueProposition content={dataGovernanceContent.whyChooseUs} />
      <DiscoveryWorkshop />
      <AccordionSection content={dataGovernanceContent.FAQ} />
      <Footer />
    </div>
  );
};

export default DataGovernance;
