import React from 'react'
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import Footer from "../../footer/Footer";
import ProductsBanner from "../banner/ProductsBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProps from "../../swimlinesection/ValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import AccordionSection from "../../accordion/Accordion";
import { integrationsContent } from './instegrationsContent';
import Meta from '../../Meta';
const Integrations = () => {
  return (
    <div>
  <Meta
        title={integrationsContent.meta.title}
        description={integrationsContent.meta.description}
        image={integrationsContent.meta.image}
        locale={integrationsContent.meta.locale}
        siteName={integrationsContent.meta.siteName}
        type={integrationsContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <ProductsBanner content={integrationsContent.banner} />
      <CounterStats content={integrationsContent.stats} />
      <ValueProps content={integrationsContent.valueProps} />
      <ValueProposition content={integrationsContent.whyChooseUs} />
      <DiscoveryWorkshop />
      <AccordionSection content={integrationsContent.FAQ} />
      <Footer />
    </div>
  )
}

export default Integrations
