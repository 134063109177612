import React from "react";
import Meta from "../../Meta";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import ProductsBanner from "../banner/ProductsBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProps from "../../swimlinesection/ValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import AccordionSection from "../../accordion/Accordion";
import Footer from "../../footer/Footer";
import { FoundationModalContent } from "./foundationModalContent";

const FoundationModal = () => {
  return (
    <div>
      <Meta
        title={FoundationModalContent.meta.title}
        description={FoundationModalContent.meta.description}
        image={FoundationModalContent.meta.image}
        locale={FoundationModalContent.meta.locale}
        siteName={FoundationModalContent.meta.siteName}
        type={FoundationModalContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <ProductsBanner content={FoundationModalContent.banner} />
      <CounterStats content={FoundationModalContent.stats} />
      <ValueProps content={FoundationModalContent.valueProps} />
      <ValueProposition content={FoundationModalContent.whyChooseUs} />
      <DiscoveryWorkshop />
      <AccordionSection content={FoundationModalContent.FAQ} />
      <Footer />
    </div>
  );
};

export default FoundationModal;
