import Header from "../header/Header";
import Filler from "../header/Filler";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
const NoPageFound = () => (
  <>
    <Header />
    <Filler />
    <div className="outerContainer">
      <div className="innerContainer">
        <div className="no-page-found-container">
          <div className="no-page-found-image">
            <img src="/images/404.svg" alt="page not found image" width={600} />
          </div>
          <h2 className="no-page-found-title  sectionTitle">
            Opps! Page not found
          </h2>
          <div className="no-page-found-description  para">
            Looks like you've taken a wrong turn. We couldn't find the page you
            were looking for. But don't worry, we're here to guide you back on
            track.
          </div>
           
        <Link to="/">
            <button className="button bannerSecondaryButton"> 
                Back to Home
            </button>
        </Link>
        </div>
      </div>
    </div>
    <Footer />
  </>
);
export default NoPageFound;
