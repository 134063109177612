import Divider from "../../Divider";
import React from "react";
import { Link } from "react-router-dom";

const NewsItems = (props) => {
  const NewsContent = props.content;
  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <h2 className="news-details-container-title sectionTitle">News</h2>
          <Divider />
          <div className="news-details-container">
            {NewsContent.map((news, index) => (
              <Link
                to={news.url ? `/news/${news.url}` : `/news`}
                className="news-details-items-link"
              >
                <div className="news-details-items" key={index}>
                  <div className="news-details-items-image">
                    <img src={news.imageURL} width={350} alt="News" />
                  </div>
                  <h4 className="news-details-items-title"> {news.title}</h4>
                  <div>
                    <Link
                      to={news.url ? `/news/${news.url}` : `/news/`}
                      className="news-details-items-readmore"
                    >
                      Read more...
                    </Link>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsItems;
