import React from 'react'

export default function Divider() {
  return (
    <>
         <div className="divider-bar-container">
        <span className="bar"></span>
      </div>
    </>
  )
}
