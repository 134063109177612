import { Drawer, Modal } from "antd";
import { useState } from "react";
import { IoClose } from "react-icons/io5";
import CareerForm from "./form/CareerForm";
import { Button } from "@material-tailwind/react";

const JobDetails = ({ job, onClose }) => {
  const [modalActive, setModalActive] = useState(false);
  const [modalClass, setModalClass] = useState("");

  const handleButtonClick = (buttonId) => {
    setModalClass(buttonId);
    setModalActive(true);
  };

  const handleModalClick = () => {
    setModalClass("out");
    setModalActive(false);
  };

  const handleCloseModal = () => {
    setModalActive(false);
  };

  return (
    <>
      <Drawer
        open={job !== null}
        className="drawer-job-details-container"
        onClose={onClose}
      >
        {job && (
          <div className="drawer-job-details">
            <h2>{job.jobTitle}</h2>
            <p>{job.JobDescription}</p>
            <p>
              <span>Job type:</span> {job.jobType}
            </p>
            <p>
              <span>Job location:</span> {job.joblocation}
            </p>
            <div className="drawer-job-details-content">
              <span>Requirements:</span>
              <br />
              <ul>
                {job.requirements.map((requirement, index) => (
                  <li key={index}>{requirement}</li>
                ))}
              </ul>
            </div>
            <div className="drawer-job-details-content">
              <span>Responsibilities:</span>
              <br />
              <ul>
                {job.responsibilities.map((responsibility, index) => (
                  <li key={index}>{responsibility}</li>
                ))}
              </ul>
            </div>
            <div className="drawer-job-details-button ">
              <Button
                className="button"
                id="four"
                onClick={() => handleButtonClick("four")}
                type="submit"
              >
                Apply now!
              </Button>
            </div>
          </div>
        )}

        <Modal
          open={modalActive}
          onCancel={handleCloseModal}
          footer={null}
          closable={false}
          width={900}
        >
          <CareerForm job={job} />
          <button className="form-close-button" onClick={handleCloseModal}>
            <IoClose />
          </button>
        </Modal>
      </Drawer>
    </>
  );
};

export default JobDetails;
