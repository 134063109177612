import React from "react";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import CareerDetails from "./CareerDetails";
import Footer from "../../footer/Footer";
import { careerContent } from "./careerContent";
const CareerOpenings = () => {
  return (
    <div>
      <Header />
      <Filler />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          // maxWidth: "80rem",
          width: "100%",
        }}
        className="job-openings-title"
      >
        <h2 className="bannerTitle">Join Our Team</h2>
      </div>
      <CareerDetails content={careerContent.jobItems} />
      <Footer />
    </div>
  );
};

export default CareerOpenings;
