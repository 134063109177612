import Divider from "../Divider";
import SignUpModal from "../modal/SignUpModal";
import { useRef, useState } from "react";
import { Button } from "@material-tailwind/react";
import { motion, useInView, useScroll, useTransform } from "framer-motion";

const DiscoveryWorkshop = (props) => {
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen
  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.3,
      },
    },
  };

  const item = {
    hidden: { scale: 0.8 },
    visible: {
      scale: 1,
    },
  };
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  return (
    <>
      <div className="outerContainer discovery-workshop-outer-container">
        <motion.div
          className="innerContainer discovery-workshop-inner-container"
          style={{ scale }}
          ref={ref}
        >
          <div className="discovery-workshop-container">
            <div className="discovery-workshop-content-wrapper">
              <div className="discovery-workshop-header">
                <h2 className="sectionTitle">
                  Start Your AI Journey with Our Discovery Workshop
                </h2>
                <p className="para">
                  Cut through the clutter of AI buzzwords and uncover real,
                  actionable solutions tailored to your business. Our Discovery
                  Workshop is your starting point to:
                </p>
                <Divider />
              </div>

              <motion.div
                className="discovery-workshop-list"
                ref={ref}
                variants={container}
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
              >
                <motion.div
                  className="discovery-workshop-list-card card-1"
                  variants={item}
                >
                  <div className="discovery-workshop-list-content">
                    <img
                      src="/images/icons/home/search.svg"
                      alt="pointer"
                    />
                    <h3>Identify impactful AI use cases</h3>
                  </div>
                </motion.div>
                <motion.div
                  className="discovery-workshop-list-card card-2"
                  variants={item}
                >
                  <div className="discovery-workshop-list-content">
                    <img
                      src="/images/icons/home/accessibility.svg"
                      alt="pointer"
                      style={{ marginRight: "10px" }}
                    />
                    <h3>Assess feasibility with expert analysis</h3>
                  </div>
                </motion.div>
                <motion.div
                  className="discovery-workshop-list-card card-3"
                  variants={item}
                >
                  <div className="discovery-workshop-list-content">
                    <img
                      src="/images/icons/home/visual.svg"
                      alt="pointer"
                      style={{ marginRight: "10px" }}
                    />
                    <h3>Visualize solutions and understand their ROI</h3>
                  </div>
                </motion.div>
                <motion.div
                  className="discovery-workshop-list-card card-4"
                  variants={item}
                >
                  <div className="discovery-workshop-list-content">
                    <img
                      src="/images/icons/home/roadmap.svg"
                      alt="pointer"
                      style={{ marginRight: "10px" }}
                    />
                    <h3>Chart a detailed roadmap for AI implementation</h3>
                  </div>
                </motion.div>
              </motion.div>

              <div className="discovery-workshop-button-section">
                <div className="discovery-workshop-button-container">
                  <Button
                    className="discovery-workshop-button button"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Schedule Your Workshop
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </>
  );
};

export default DiscoveryWorkshop;
