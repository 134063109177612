import React from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const PromoVideo = (props) => {
  const { scrollYProgress } = useScroll();
  // scale the video based on scroll position (scrollYProgress)
  // initial scale is .7 and final scale is 1.5 (70% to 150%)
  const scale = useTransform(scrollYProgress, [0, 0.8], [1, 1.5]);

  return (
    <>
      <div className=" promo-video-container">
        <motion.div
          style={{
            scale,
          }}
          className="innerContainer"
        >
          <video
            style={{
              scaleY: scrollYProgress,
            }}
            autoPlay
            muted
            controls
            loop
            className="promo-video"
          >
            <source src={props.videoURL} />
          </video>
        </motion.div>
      </div>
    </>
  );
};

export default PromoVideo;
