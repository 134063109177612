import React from "react";
import { MdTask } from "react-icons/md";
import Divider from "../Divider";

const Pillers = (props) => {
  const pillers = props.content;
  return (
    <>
      <div className="outerContainer generative-ai-piller-outer-container">
        <div className="innerContainer generative-ai-piller-inner-container">
          <div className="generative-ai-piller-section-title sectionTitle">
            Pillars of Generative AI for Enterprise
          </div>
          <Divider />
          <div className="generative-ai-piller-container">
            {pillers.map((piller) => (
              <div className="generative-ai-piller">
                <div className="generative-ai-piller-image">{piller.icon}</div>
                <div className="generative-ai-piller-content-container">
                  <div className="generative-ai-piller-title">
                    <h3 className="paragraphTitle">{piller.title}</h3>
                  </div>
                  <div className="generative-ai-piller-description para">
                    <p>{piller.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pillers;
