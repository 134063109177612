import React from "react";
import Meta from "../../Meta";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import SolutionBanner from "../banner/SolutionBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import IntegrationSection from "../integration/IntegrationSection";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import AccordionSection from "../../accordion/Accordion";
import Footer from "../../footer/Footer";
import { informationTechnologyContent } from "./informationTechnologyContent";

const InformationTechnology = () => {
  return (
    <div>
      <Meta
        title={informationTechnologyContent.meta.title}
        description={informationTechnologyContent.meta.description}
        image={informationTechnologyContent.meta.image}
        locale={informationTechnologyContent.meta.locale}
        siteName={informationTechnologyContent.meta.siteName}
        type={informationTechnologyContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={informationTechnologyContent.banner} />
      <CounterStats content={informationTechnologyContent.stats} />
      <InnerPageValueProps
        content={informationTechnologyContent.valueProps}
      />
      <ValueProposition
        content={informationTechnologyContent.whyChooseUs}
      />
      {/* <IntegrationSection
        content={informationTechnologyContent.integrations}
      /> */}
      <DiscoveryWorkshop />
      {/* <WebinarSection /> */}
      <AccordionSection content={informationTechnologyContent.FAQ} />
      <Footer />
    </div>
  );
};

export default InformationTechnology;
