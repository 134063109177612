import React from "react";
import Divider from "../Divider";
import InnerPageFeaturesItem from "./InnerPageFeaturesItem";

const InnerPageFeatures = (props) => {
  const InnerFeaturesContent = props.content;
  if (!InnerFeaturesContent) {
    return null; // or display a loading/error message
  }
  return (
    <>
      <div className="innerpage-features-out-container outerContainer">
        <div className="innerpage-features-inner-container innerContainer">
          <h2 className="innerpage-features-inner-container-title sectionTitle">
            Why our customers choose us?
          </h2>
          <p className="innerpage-features-inner-container-description">
            Proven and reliable solution for AI chatbot.
          </p>
          <Divider />
          <div className="innerpage-features-card-container">
            {InnerFeaturesContent.map((card, index) => (
              <InnerPageFeaturesItem key={index} content={card} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default InnerPageFeatures;
