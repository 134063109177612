import React from "react";
import Meta from "../../components/Meta";
import Header from "../../components/header/Header";
import Filler from "../../components/header/Filler";
import SolutionBanner from "../../components/solutions/banner/SolutionBanner";
import ValueProps from "../../components/swimlinesection/ValueProps";
import ValueProposition from "../../components/value-proposition/ValueProposition";
import DemoVideo from "../../components/demo-video/DemoVideo";
import AccordionSection from "../../components/accordion/Accordion";
import Footer from "../../components/footer/Footer";
import { AiAgentAssistContent } from "./aiAgentAssistContent";
import CounterStats from "../../components/stats-counter/Stats-counter";

const AiAgentAssist = () => {
  return (
    <div>
      <Meta
        title={AiAgentAssistContent.meta.title}
        description={AiAgentAssistContent.meta.description}
        image={AiAgentAssistContent.meta.image}
        locale={AiAgentAssistContent.meta.locale}
        siteName={AiAgentAssistContent.meta.siteName}
        type={AiAgentAssistContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={AiAgentAssistContent.banner} />
      <CounterStats content={AiAgentAssistContent.stats} />
      <ValueProps content={AiAgentAssistContent.valueProps} />
      <ValueProposition content={AiAgentAssistContent.whyChooseUs} />
      <AccordionSection content={AiAgentAssistContent.FAQ} />
      <Footer />
    </div>
  );
};

export default AiAgentAssist;
