export const legalContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Our AI-powered solutions are transforming the legal field, focusing on simplifying the complexity of legal processes and enhancing the accessibility of legal information. With an emphasis on four key areas, we are making it easier for professionals to manage, search, and analyze legal documents and data.",
    id: "1",
    image: {
      img1: "/images/solutions/legal/legal-banner-5.jpg",
      img2: "/images/solutions/legal/legal-banner-2.jpg",
      img3: "/images/solutions/legal/legal-banner-3.jpg",
      img4: "/images/solutions/legal/legal-banner-1.jpg",
      img5: "/images/solutions/legal/legal-banner-4.jpg",
    },
    background: "/images/solutions/healthcare/banner.jpg",
    sectionTile: "",
    mainTitle: "Legal",
    title: "Revolutionizing the Legal Landscape with AI",
  },

  valueProps: [
    {
      id: "1",
      imageURL: "/images/solutions/legal/woman-summarizing-work-meeting.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Simplified Case Summaries",
      subTitleOne: "Quick Case Overviews",
      subTitleTwo: "Efficient Understanding",
      description:
        "Transforming dense legal documents into concise, easy-to-understand summaries.",
      descriptionOne:
        "Instantly generate brief descriptions of case histories, providing a clear snapshot of essential facts and outcomes.",
      descriptionTwo:
        "Enhance comprehension of complex cases, enabling faster decision-making and strategy development.",
    },

    {
      id: "2",
      imageURL: "/images/solutions/legal/justice-law-conceptmale-judge.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Enhanced Search Capabilities",
      subTitleOne: "Intuitive Search Interface",
      subTitleTwo: "Targeted Case Retrieval",
      description:
        "Revolutionizing how legal professionals find the information they need.",
      descriptionOne:
        "Utilize natural language processing for more accurate and relevant search results, tailored to your specific queries.",
      descriptionTwo:
        "Easily find specific cases or types of cases, reducing the time spent on research and increasing focus on analysis.",
    },
    {
      id: "3",
      imageURL: "/images/solutions/legal/104401.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Detailed Case Information",
      subTitleOne: "Comprehensive Case Details",
      subTitleTwo: "Type-Specific Insights",
      description:
        "Providing in-depth insights into cases with unparalleled ease and precision.",
      descriptionOne:
        "Access detailed information about particular cases, including parties involved, legal arguments, and judgments.",
      descriptionTwo:
        "Filter and retrieve information based on case types, enabling specialized research and understanding of patterns within specific legal areas.",
    },
    {
      id: "4",
      imageURL: "/images/solutions/legal/pexels-pavel-danilyuk-8111853.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Straightforward Analytics",
      subTitleOne: "Accessible Data Insights",
      subTitleTwo: "Interactive Legal Timelines",
      description:
        "Demystifying legal analytics with simple, actionable insights.",
      descriptionOne:
        " Our analytics are designed to be easily understandable, providing clear visualizations and summaries of legal trends and outcomes.",
      descriptionTwo:
        "Enhance your analysis and understanding of cases with dynamic timelines that visually map out the key events, filings, and decisions throughout a case's history.",
    },
  ],
  stats: [
    {
      id: "1",
      count: "80",
      title: "improvement in case research efficiency",
    },
    {
      id: "2",
      count: "70",
      title: "decrease in document processing time",
    },
    {
      id: "3",
      count: "250",
      title: "increase in case volume handling",
    },
  ],

  innerFeatures: [
    {
      id: "1",
      title: "Smart Language Model",
      imageURL: "/images/solutions/healthcare/smart.svg",
      point1: "Enhanced Efficiency",
      point2: "24/7 Availability",
      point3: "Scalability and Consistency",
      point4: "Personalized and Contextualized Interaction",
    },
    {
      id: "1",
      title: "Powered by ChatGPT",
      imageURL: "/images/solutions/healthcare/ai.svg",
      point1: "State-of-the-art Natural Language Processing",
      point2: "Deep Learning Architecture",
      point3: "Large-Scale Pretraining",
      point4:
        "Advanced and Contextual Language Understanding and Generation Capabilities ",
    },
    {
      id: "1",
      title: "Secure and compliant conversations ",
      imageURL: "/images/solutions/healthcare/complaint.svg",
      point1: "Data Privacy",
      point2: "Regulatory Compliance",
      point3: "Secure Infrastructure",
      point4: "Encrypted Communication",
    },
  ],

  meta: {
    title:
      "iNextLabs Healthcare Solutions - Advancing Innovations in Healthcare Technology",
    description:
      "Explore iNextLabs' groundbreaking solutions in healthcare technology. Discover how we're revolutionizing patient care, medical research, and diagnostics through cutting-edge innovations. Join us in shaping the future of healthcare.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
