import React from "react";
import Meta from "../../Meta";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import SolutionBanner from "../banner/SolutionBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import IntegrationSection from "../integration/IntegrationSection";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import AccordionSection from "../../accordion/Accordion";
import Footer from "../../footer/Footer";
import { procurementContent } from "./procurementContent";

const Procurement = () => {
  return (
    <div>
      <Meta
        title={procurementContent.meta.title}
        description={procurementContent.meta.description}
        image={procurementContent.meta.image}
        locale={procurementContent.meta.locale}
        siteName={procurementContent.meta.siteName}
        type={procurementContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={procurementContent.banner} />
      <CounterStats content={procurementContent.stats} />
      <InnerPageValueProps content={procurementContent.valueProps} />
      <ValueProposition content={procurementContent.whyChooseUs} />
      <DiscoveryWorkshop />
      {/* <WebinarSection /> */}
      <AccordionSection content={procurementContent.FAQ} />
      <Footer />
    </div>
  );
};

export default Procurement;
