import React, { useRef } from "react";
import Header from "../header/Header";
import Filler from "../header/Filler";
import SolutionBanner from "../solutions/banner/SolutionBanner";
import { avatarAssistantsContent } from "./avatarAssistantContent";
import Features from "../features/Features";
import ValueProps from "../swimlinesection/ValueProps";
import Meta from "../Meta";
import ValueProposition from "../value-proposition/ValueProposition";
import DemoVideo from "../demo-video/DemoVideo";
import Footer from "../footer/Footer";
import AccordionSection from "../accordion/Accordion";
const AIAvatarAssist = () => {
  return (
    <div>
      <Meta
        title={avatarAssistantsContent.meta.title}
        description={avatarAssistantsContent.meta.description}
        image={avatarAssistantsContent.meta.image}
        locale={avatarAssistantsContent.meta.locale}
        siteName={avatarAssistantsContent.meta.siteName}
        type={avatarAssistantsContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={avatarAssistantsContent.banner} />
      {/* <WhyChooseUs content={avatarAssistantsContent.whyChooseUs} /> */}
      <Features content={avatarAssistantsContent.whyChooseUs} />
      <ValueProps content={avatarAssistantsContent.valueProps} />
      <ValueProposition content={avatarAssistantsContent.valueProposition} />
      <DemoVideo content={avatarAssistantsContent.demoVideo} />
      <AccordionSection content={avatarAssistantsContent.FAQ} />
      <Footer />
    </div>
  );
};

export default AIAvatarAssist;
