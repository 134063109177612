import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown } from "lucide-react";

const AccordionSection = (props) => {
  const [openAccordions, setOpenAccordions] = useState([]);

  const handleToggleAccordion = (index) => {
    const newOpenAccordions = [...openAccordions];
    if (newOpenAccordions.includes(index)) {
      newOpenAccordions.splice(newOpenAccordions.indexOf(index), 1);
    } else {
      newOpenAccordions.push(index);
    }
    setOpenAccordions(newOpenAccordions);
  };

  return (
    <>
      <div className="outerContainer accordion-outer-container">
        <div className="innerContainer accordion-inner-container">
          <div className="accordion-container">
            <div className="accordion-section-left">
              <h2 className="sectionTitle left">Frequently Asked Questions</h2>
              <p className="para">
                Still have unanswered questions? Feel free to reach out to us at
                <span>info@inextlabs.com</span>
              </p>
            </div>

            <div className="accordion-section-right">
              {props.content.map((item, index) => (
                <AccordionItem
                  key={index}
                  title={item.question}
                  content={item.answer}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordionSection;

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion-item">
      <button
        className="accordion-item-button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="accordion-item-question">{title}</span>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <ChevronDown className="h-5 w-5" />
        </motion.div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="accordion-item-answer">{content}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
