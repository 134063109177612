import React, { useState } from "react";
import Filler from "../header/Filler";
import HomeBanner from "../banner/HomeBanner";
import { homeContent } from "./HomeContent";
import InnerPageValueProps from "../innerpageswimlane/InnerPageValueProps";
import Header from "../header/Header";
import Articles from "../articles/Articles";
import Footer from "../footer/Footer";
import Meta from "../Meta";
import DiscoveryWorkshop from "../discovery-workshop/DiscoveryWorkshop";
import ValueProposition from "../value-proposition/ValueProposition";
import PartnersLogo from "../partners-logo/PartnersLogo";
import AssistantSolutions from "../assistant-solutions/AssistantSolutions";
import IndustryType from "../industry-types/IndustryType";
import BuisnessStatement from "../statement/BuisnessStatement";
import { AvatarAssistantsPage } from "../ai-avatar-assist/AIAvatarAssist";
import Redirect from "../redirect-page/Redirect";

const Home = () => {
  // const [isWebinarModalOpen, setIsWebinarModalOpen] = useState(false); // Set initial state as needed
  return (
    <>
      <Meta
        title={homeContent.meta.title}
        description={homeContent.meta.description}
        image={homeContent.meta.image}
        locale={homeContent.meta.locale}
        siteName={homeContent.meta.siteName}
        type={homeContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <HomeBanner content={homeContent.banner} />
      <IndustryType content={homeContent.solutionFeatures} />
      <BuisnessStatement />
      <AssistantSolutions content={homeContent.assistantSolutions} />
      <InnerPageValueProps content={homeContent.valueProps} />
      <Redirect content={homeContent.redirectPage} />
      <ValueProposition content={homeContent.whyChooseUs} />
      <DiscoveryWorkshop />
      <PartnersLogo content={homeContent.partners} />
      <Articles content={homeContent.articles} />
      <Footer />
    </>
  );
};

export default Home;
