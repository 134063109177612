import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos";
import { Carousel } from "antd";
import Select from "react-select";

import Header from "../../header/Header";
import Filler from "../../header/Filler";
import Footer from "../../footer/Footer";
import Meta from "../../Meta";
import Divider from "../../Divider";
import Modal from "../../modal/Modal";
import CompanyBanner from "../banner/CompanyBanner";
import { careerContent } from "./careerContent";
import { TiAttachment } from "react-icons/ti";
import { TfiQuoteLeft } from "react-icons/tfi";
import { MdDelete } from "react-icons/md";
import { Button } from "@material-tailwind/react";

const Career = () => {
  return (
    <>
      <Meta
        title={careerContent.meta.title}
        description={careerContent.meta.description}
        image={careerContent.meta.image}
        locale={careerContent.meta.locale}
        siteName={careerContent.meta.siteName}
        type={careerContent.meta.type}
        noindex={false}
      />

      <Header />
      <Filler />
      <CompanyBanner content={careerContent.banner} />
      <CareerBenefits content={careerContent.benefits} />
      <CareerOurTeams content={careerContent.ourTeams} />
      <CareerOurTeamLocation />
      <CareerJobInterest />
      <Footer />
    </>
  );
};

const CareerBenefits = (probs) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="career-benefits-outer-container outerContainer">
        <div className="career-benefits-inner-container innerContainer">
          <div className="career-benefits-image-wrapper">
            <img
              src="/images/company/career/business-success-concept-with-chessboard-side-view-man-placing-figure-pyramid-blocks.png"
              alt="Career Benefits"
            />
          </div>
          <div className="career-benefits-content-wrapper">
            <h2 class="career-benefits-section-title sectionTitle left margin">
              Benefits at iNextLabs
            </h2>
            <p class="para">
              iNextLabs is committed to fostering a supportive work environment
              by offering a range of benefits designed to enhance the overall
              well-being of our employees. Here’s a closer look at some of the
              key advantages:
            </p>
            <div className="career-benefits-content para">
              {probs.content.map((benefit) => (
                <div data-aos="fade-up">
                  <h3 class="paragraphTitle">
                    {benefit.icon}
                    {benefit.title}
                  </h3>
                  <p class="para">{benefit.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CareerOurTeams = (props) => {
  const ourTeams = props.content;
  return (
    <>
      <div className="career-our-teams-outer-container outerContainer">
        <div className="career-our-teams-inner-container innerContainer">
          <div className="career-our-teams-header">
            <h2 class="career-our-teams-header-title sectionTitle margin">
              Few Words from our Team
            </h2>
            <p class="career-our-teams-header-text para">
              We are a team of passionate individuals who are committed to
              creating a positive impact on the world through our work.
            </p>
            <Divider />
          </div>

          <div className="career-our-teams-body">
            <Carousel autoplay>
              {ourTeams.map((team, index) => (
                <div className="career-our-team-card" key={index}>
                  <div className="career-our-team-card-image-wrapper">
                    <div className="career-our-team-card-image">
                      <img src={team.imageURL} alt="" />
                    </div>
                  </div>
                  <div className="career-our-team-card-content">
                    <TfiQuoteLeft className="career-our-team-card-icon" />
                    <div className="career-our-team-card-content-header">
                      <h3 className="career-our-team-card-content-title paragraphTitle">
                        {team.name}
                      </h3>
                      <p className="career-our-team-card-content-designation">
                        {team.designation}
                      </p>
                    </div>
                    <p className="career-our-team-card-content-text para">
                      {team.text}
                    </p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

const CareerOurTeamLocation = (props) => {
  return (
    <>
      <div className="career-our-teams-locations-outer-container outerContainer">
        <div className="career-our-teams-locations-inner-container innerContainer">
          <div className="career-our-teams-locations-header">
            <h2 class="sectionTitle margin">We're Growing Our Stellar Team</h2>
            <p class="para center">
              Your opportunity to thrive in a dynamic,rapidly expanding
              environment!
            </p>
            <Divider />
            <img src="/images/company/career/map.png" alt="" width={"90%"} />
          </div>
        </div>
      </div>
    </>
  );
};

const CareerJobInterest = (props) => {
  return (
    <>
      <div className="career-job-interest-outer-container outerContainer">
        <div className="career-job-interest-inner-container innerContainer">
          <h2 class="sectionTitle margin">
            Don’t see the job you’re looking for?
          </h2>
          <p class="para center">
            Fill in your details below and we’ll reach out to you when there’s
            an opening!
          </p>
          <Link to="/career/register-your-interest/new">
            <Button className="button">Add Details</Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export const CareerJobInterestForm = () => {
  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [response, SetResponse] = useState(null);
  const [errorMessage, SetErrorMessage] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const closeModal = () => {
    setShowModal(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFile(file);
      setValue("file", file);
    }
  };
  const onSubmit = async (formValues) => {
    setShowModal(true);
    setLoading(true);
    const formData = new FormData();
    formData.append("name", formValues.name);
    formData.append("email", formValues.email);
    formData.append("phone", formValues.phone);
    formData.append("linkedin", formValues.linkedin);
    formData.append("location", formValues.location);
    formData.append("hearAboutUs", formValues.hearAboutUs);
    formData.append("noticeperiod", formValues.noticeperiod);
    formData.append("currentctc", formValues.currentctc);
    formData.append("expectedctc", formValues.expectedctc);
    formData.append("file", uploadedFile);
    formData.append("team", selectedTeam.value);

    if (formValues.comments) {
      formData.append("comments", formValues.comments);
    }

    try {
      const response = await fetch("/career-interest", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      setLoading(false);

      if (response.ok) {
        SetResponse(data.message);
        setShowResult(true);
        setShowFailure(false);
      } else {
        SetErrorMessage(data.message);
        setShowFailure(true);
        setShowResult(false);
      }
    } catch (error) {
      setLoading(false);
      setShowFailure(true);
      SetErrorMessage(
        "The server encountered an unexpected condition that prevented it from fulfilling the request."
      );
      setShowResult(false);
    }

    reset();
  };
  const teams = [
    { value: "Technical", label: "Technical" },
    { value: "Marketing", label: "Marketing" },
    { value: "Sales", label: "Sales" },
    { value: "Design", label: "Design" },
    { value: "Project Management", label: "Project Management" },
    { value: "Testing", label: "Testing" },
    { value: "Support", label: "Support" },
  ];
  return (
    <>
      <Header />
      <Filler />
      <div className="career-job-interest-form-outer-container outerContainer">
        <div className="career-job-interest-form-inner-container innerContainer">
          <div className="career-job-interest-form-hero-section">
            <h2 class="sectionTitle margin">Register Your Interest</h2>
            <p class="para center">
              Fill in your details below and we’ll reach out to you when there’s
              an opening!
            </p>
          </div>
          <div className="career-job-interest-form-container">
            <form
              id="career-job-interest-form"
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <h3 className="paragraphTitle">1.Personal Details</h3>
              <div className="career-job-interest-form-row-container">
                <div className="career-job-interest-form-name">
                  <input
                    id="name"
                    className="row-input"
                    type="text"
                    placeholder="Full name"
                    {...register("name", { required: "Full name is required" })}
                  />
                  {errors.name && (
                    <span className="error-message">{errors.name.message}</span>
                  )}
                </div>
                <div className="career-job-interest-form-email">
                  <input
                    id="email"
                    className="row-input"
                    type="email"
                    placeholder="Email address"
                    {...register("email", {
                      required: "Email address is required",
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="error-message">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="career-job-interest-form-phone">
                  <input
                    id="phone"
                    className="row-input"
                    type="text"
                    placeholder="Phone number"
                    {...register("phone", {
                      required: "Phone number is required",
                    })}
                  />
                  {errors.phone && (
                    <span className="error-message">
                      {errors.phone.message}
                    </span>
                  )}
                </div>
              </div>
              <h3 className="paragraphTitle">2.Upload Resume</h3>
              <div className="career-job-interest-form-row-container">
                <div className="career-job-interest-form-resume">
                  {uploadedFile?.name ? (
                    <div className="career-job-interest-form-upload-label row-input">
                      {uploadedFile?.name}
                      <MdDelete
                        style={{ marginLeft: "10px", color: "#f05742" }}
                        onClick={() => {
                          setUploadedFile(null);
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        id="file-upload"
                        onChange={onFileChange}
                        accept=".pdf,.docx"
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="file-upload"
                        className="career-job-interest-form-upload-label row-input"
                      >
                        <TiAttachment size={22} /> <span> Attach Resume</span>
                      </label>
                    </>
                  )}
                </div>
              </div>
              <h3 className="paragraphTitle">
                3.Which department are you a part of?
              </h3>
              <div className="career-job-interest-form-row-container">
                <div className="career-job-interest-form-select row-input">
                  <Select
                    options={teams}
                    onChange={(selectedOption) =>
                      setSelectedTeam(selectedOption)
                    }
                  />
                </div>
              </div>
              <h3 className="paragraphTitle">4.Tells us more about you</h3>
              <div className="career-job-interest-form-row-container">
                <div className="career-job-interest-question">
                  <input
                    id="linkedin-url"
                    className="row-input"
                    type="text"
                    placeholder="Please share your Linkedin profile link."
                    {...register("linkedin", {
                      required: "This feild is required",
                    })}
                  />
                  {errors.linkedin && (
                    <span className="error-message">
                      {errors.linkedin.message}
                    </span>
                  )}
                </div>
                <div className="career-job-interest-question">
                  <input
                    id="location"
                    className="row-input"
                    type="text"
                    placeholder="Where are you currently located?"
                    {...register("location", {
                      required: "This feild is required",
                    })}
                  />
                  {errors.location && (
                    <span className="error-message">
                      {errors.location.message}
                    </span>
                  )}
                </div>
                <div className="career-job-interest-question">
                  <input
                    id="hearAboutUs"
                    className="row-input"
                    type="text"
                    placeholder="How did you hear about us?"
                    {...register("hearAboutUs", {
                      required: "This feild is required",
                    })}
                  />
                  {errors.hearAboutUs && (
                    <span className="error-message">
                      {errors.hearAboutUs.message}
                    </span>
                  )}
                </div>
                <div className="What is your current notice period?">
                  <input
                    id="notice-period"
                    className="row-input"
                    type="text"
                    placeholder="What is your current notice period?"
                    {...register("noticeperiod", {
                      required: "This feild is required",
                    })}
                  />
                  {errors.noticeperiod && (
                    <span className="error-message">
                      {errors.noticeperiod.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="career-job-interest-form-submit-button">
                <Button
                  id="career-job-interest-form-submit"
                  className="button"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </form>
            {showModal && (
              <Modal
                loading={loading}
                showResult={showResult}
                showFailure={showFailure}
                closeModal={closeModal}
                response={response}
                errorMessage={errorMessage}
              />
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Career;
