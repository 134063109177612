import React from 'react'
import Meta from '../../Meta'
import Header from '../../header/Header'
import Filler from '../../header/Filler'
import ProductsBanner from '../banner/ProductsBanner'
import CounterStats from '../../stats-counter/Stats-counter'
import ValueProps from '../../swimlinesection/ValueProps'
import ValueProposition from '../../value-proposition/ValueProposition'
import DiscoveryWorkshop from '../../discovery-workshop/DiscoveryWorkshop'
import AccordionSection from '../../accordion/Accordion'
import { analyzerContent } from './analyzerContent'
import Footer from '../../footer/Footer'

const Analyzer = () => {
  return (
    <div>
      <Meta
        title={analyzerContent.meta.title}
        description={analyzerContent.meta.description}
        image={analyzerContent.meta.image}
        locale={analyzerContent.meta.locale}
        siteName={analyzerContent.meta.siteName}
        type={analyzerContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <ProductsBanner content={analyzerContent.banner} />
      <CounterStats content={analyzerContent.stats} />
      <ValueProps content={analyzerContent.valueProps} />
      <ValueProposition content={analyzerContent.whyChooseUs} />
      <DiscoveryWorkshop />
      <AccordionSection content={analyzerContent.FAQ} />
      <Footer />
    </div>
  )
}

export default Analyzer
