import React, { useState } from "react";
import {
  Home,
  Settings,
  Key,
  Bell,
  Users,
  Cpu,
  LineChart,
  BarChart,
  UserPlus,
  Shield,
  Globe,
  Archive,
  FileText,
  BookOpen,
  Video,
  Briefcase,
  ShoppingBag,
  Monitor,
  Package,
  Heart,
  Coffee,
  Store,
  Globe2,
  Info,
  UserCheck,
  Phone,
  Calendar,
  X,
  Menu,
  ChevronDown,
  Building2,
  FolderCog,
  Lightbulb,
  LayoutPanelLeft,
  Brain,
} from "lucide-react";
import { Link } from "react-router-dom";

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(null); // Track which submenu is open

  const menuItems = [
    { label: "Generative AI", href: "/generative-ai", icon: Brain },
    {
      label: "Products",
      icon: LayoutPanelLeft,
      hasSubNav: true,
      subItems: [
        {
          label: "inFlow Assistants",
          href: "/products/ai-assistants",
          icon: UserPlus,
        },
        {
          label: "inFlow Analyzer",
          href: "/products/ai-analyzer",
          icon: LineChart,
        },
        { label: "inFlow Insights", href: "/products/ai-insights", icon: BarChart },
        {
          label: "inFlow Agent Assist",
          href: "/products/ai-agent-assist",
          icon: Users,
        },
        {
          label: "Foundation Model",
          href: "/products/foundation-model",
          icon: Cpu,
        },
        { label: "Security", href: "/products/security", icon: Shield },
        { label: "Omnichannel", href: "/products/omnichannel", icon: Globe },
        {
          label: "Data Governance",
          href: "/products/data-governance",
          icon: Archive,
        },
        {
          label: "Integrations",
          href: "/products/integrations",
          icon: Package,
        },
      ],
    },
    {
      label: "Solutions",
      icon: Lightbulb,
      hasSubNav: true,
      subItems: [
        {
          label: "Sales & Marketing Automation",
          href: "/solution/sales-and-marketing",
          icon: LineChart,
        },
        {
          label: "Customer Support",
          href: "/solution/customer-support-automation",
          icon: Phone,
        },
        {
          label: "Human Resources",
          href: "/solution/human-resources",
          icon: UserCheck,
        },
        {
          label: "IT",
          href: "/solution/information-technology",
          icon: Monitor,
        },
        {
          label: "Procurement",
          href: "/solution/procurement",
          icon: ShoppingBag,
        },
        { label: "Healthcare", href: "/solution/healthcare", icon: Heart },
        { label: "BFSI", href: "/solution/bank", icon: Briefcase },
        { label: "FnB", href: "/solution/restaurant", icon: Coffee },
        { label: "Retail", href: "/solution/retail", icon: Store },
        {
          label: "Other Industries",
          href: "/solution/other-industries",
          icon: Globe2,
        },
      ],
    },
    { label: "Partners", href: "/partner", icon: UserCheck },
    {
      label: "Resources",
      icon: FolderCog,
      hasSubNav: true,
      subItems: [
        { label: "Case Studies", href: "/resources/casestudy", icon: FileText },
        { label: "Blogs", href: "/resources/blog", icon: BookOpen },
        { label: "Library", href: "/resources/library", icon: Archive },
        { label: "Webinars", href: "/resources/webinars", icon: Video },
      ],
    },
    {
      label: "Company",
      icon: Building2,
      hasSubNav: true,
      subItems: [
        { label: "About Us", href: "/about-us", icon: Info },
        { label: "Careers", href: "/career", icon: Users },
        { label: "Contact", href: "/contact", icon: Phone },
        { label: "Events & News", href: "/news", icon: Calendar },
      ],
    },
  ];

  const handleSettingsClick = (e, label) => {
    e.preventDefault();
    setSubMenuOpen((prev) => (prev === label ? null : label));
  };

  return (
    <div className="mobile-nav">
      {isOpen && <div className="overlay" onClick={() => setIsOpen(false)} />}

      <header className="header">
        <div className="header__container">
          <div className="header__content">
            <div className="brand">
              <Link to="/">
                <img
                  src="/images/general/inextlabs-logo.png"
                  alt="iNextLabs Logo"
                  loading="lazy"
                  className="headerLogo"
                />
              </Link>
            </div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="menu-toggle"
              aria-label="Toggle menu"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </header>

      <nav className={`nav-menu ${isOpen ? "nav-menu--open" : ""}`}>
        <div className="nav-menu__container">
          <div className="nav-menu__items">
            {menuItems.map((item) => {
              const Icon = item.icon;

              if (item.hasSubNav) {
                const isItemOpen = subMenuOpen === item.label;
                return (
                  <div key={item.label} className="nav-item-wrapper">
                    <a
                      href="#"
                      className={`nav-item nav-item--with-subnav ${
                        isItemOpen ? "nav-item--active" : ""
                      }`}
                      onClick={(e) => handleSettingsClick(e, item.label)}
                    >
                      <div className="nav-item__content">
                        <Icon size={20} />
                        <span>{item.label}</span>
                      </div>
                      <ChevronDown
                        size={20}
                        className={`nav-item__chevron ${
                          isItemOpen ? "nav-item__chevron--open" : ""
                        }`}
                      />
                    </a>
                    <div
                      className={`subnav ${isItemOpen ? "subnav--open" : ""}`}
                    >
                      {item.subItems.map((subItem) => {
                        const SubIcon = subItem.icon;
                        return (
                          <a
                            key={subItem.label}
                            href={subItem.href}
                            className="subnav-item"
                            onClick={() => setIsOpen(false)}
                          >
                            <SubIcon size={18} />
                            <span>{subItem.label}</span>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                );
              }

              return (
                <a
                  key={item.label}
                  href={item.href}
                  className="nav-item"
                  onClick={() => setIsOpen(false)}
                >
                  <div className="nav-item__content">
                    <Icon size={20} />
                    <span>{item.label}</span>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MobileNav;
