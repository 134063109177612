import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const ArticlesItem = (props) => {
  return (
    <>
      <motion.div
        className="articlesItem-outer-container"
        variants={props.variants}
      >
        <div className="articlesItem-inner-container">
          <Link
            to={`/news/${props.content.url}`}
            className="articlesItem-card-link"
          >
            <div className="articlesItem-card">
              <div className="articlesItem-card-image">
                <img src={props.content.imageURL} alt={props.content.title} />
              </div>
              <div className="articlesItem-card-content">
                <div className="articlesItem-card-title">
                  <h3>{props.content.title}</h3>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </motion.div>
    </>
  );
};

export default ArticlesItem;
