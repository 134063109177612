import React from "react";
import Header from "../header/Header";
import Filler from "../header/Filler";
import Footer from "../footer/Footer";
import Meta from "../Meta";

const TermsOfService = () => {
  return (
    <div>
      <Meta
        title="iNextLabs - Terms of Services"
        description="Using this website, we assume that you accept our terms and conditions. These terms and conditions apply to all users, visitors, and individuals who access our site."
        noindex={false}
      />

      <Header />
      <Filler />

      <div className="outerContainer">
        <div className="innerContainer left">
          <h1 className="bannerTitle">Terms of Service</h1>
          <p className="para">
            This agreement ("Agreement") is between the customer or the entity
            the customer represents ("Customer") and iNextLabs Pte Ltd
            ("iNextLabs"). Both iNextLabs and the Customer may be individually
            referred to as a “Party” and collectively as the “Parties.” By using
            iNextLabs' services, the Customer agrees to the terms outlined in
            this Agreement.
          </p>
          <br />
          <h3 className="paragraphTitle">1.Definitions and Interpretation </h3>
          <p className="para">Certain terms are defined as follows:</p>
          <ol>
            <li className="para">
              <p>
                <b>Agreement: </b> refers to this document and any annexures or
                amendments.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Applicable Laws: </b> refers to any relevant legal
                regulations.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Cloud: </b>refers to cloud services provided by platforms
                like Microsoft Azure or Google Cloud.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Confidential Information: </b> includes any proprietary or
                sensitive data exchanged between the Parties.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Charges: </b>refers to the payments agreed upon for services
                provided.
              </p>
            </li>
            <li className="para">
              <p>
                <b>Data Controller: </b>refers to the entity that controls data,
                while "Data Processor" refers to the one that processes it.
              </p>
            </li>
          </ol>
          <h3 className="paragraphTitle">2. Scope of Services</h3>
          <p className="para">
            iNextLabs will provide access to its AI-powered solutions via its
            website, maintaining the latest versions of the software, subject to
            payment. The Customer is responsible for associated costs as
            outlined in the annexure. iNextLabs will deliver services in a SaaS
            model, accessible via the internet.
          </p>
          <br />

          <h3 className="paragraphTitle">3. Payment and Pricing</h3>
          <p className="para">
            <ol>
              <li className="para">
                <p>
                  <b>Subscription: </b> The Customer may subscribe to services
                  on a monthly or yearly basis. Payment is made by credit card,
                  and subscriptions automatically renew unless canceled seven
                  days prior to renewal.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Payment: </b> Payments are due in advance of each
                  subscription period. iNextLabs reserves the right to suspend
                  services if payment is not received by the due date. All fees
                  are non-refundable unless stated otherwise.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Upgrades and Downgrades: </b>Customers can change their
                  subscription plan at any time. Changes take effect at the next
                  billing cycle unless otherwise agreed. Upgrading may incur
                  additional fees, while downgrading may result in a loss of
                  features.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Early Termination: </b> Early termination fees may apply as
                  outlined in the subscription plan to compensate iNextLabs for
                  the loss of anticipated revenue.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Changes to Subscription Plans: </b>iNextLabs reserves the
                  right to modify its subscription plans. Customers will be
                  notified of any material changes at least 30 days in advance.
                </p>
              </li>
            </ol>
          </p>
          <br />
          <h3 className="paragraphTitle">4. Data Privacy</h3>
          <p className="para">
            The Parties agree that personal data may be exchanged during service
            provision. Both iNextLabs and the Customer must comply with
            applicable data protection laws, including the Personal Data
            Protection Act 2012 (PDPA). iNextLabs will only process data as
            instructed by the Customer and will take measures to ensure data
            security.
          </p>

          <br />
          <h3 className="paragraphTitle">5. Data Ownership</h3>
          <p className="para">
            iNextLabs acknowledges that all data created, uploaded, or generated
            by the Customer in connection with the use of iNextLabs' services
            shall remain the sole and exclusive property of the Customer.
            iNextLabs will not claim ownership of customer data and will not
            access, use, or disclose customer data except as necessary to
            perform services, comply with legal obligations, or with the express
            written consent of the Customer.
          </p>
          <br />
          <h3 className="paragraphTitle">6. Hosting Location</h3>
          <p className="para">
            iNextLabs' solutions are hosted in a Software-as-a-Service (SaaS)
            environment. The hosting location of customer data will be
            determined based on geographic considerations. iNextLabs will ensure
            that the selected data center adheres to necessary security and
            legal compliance standards. Customers will be informed of the
            initial hosting location and notified of any changes.
          </p>
          <br />
          <h3 className="paragraphTitle">7. Intellectual Property Rights</h3>
          <p className="para">
            iNextLabs retains all rights to its solutions and intellectual
            property. The Customer is granted a non-exclusive, non-transferable
            license to use the services under the terms of this Agreement. The
            Customer may not reverse-engineer, copy, or modify iNextLabs'
            software or transfer it to third parties without permission.
          </p>
          <br />
          <h3 className="paragraphTitle">
            8. Indemnity and Limitation of Liability
          </h3>
          <p className="para">
            Each Party agrees to indemnify the other for third-party claims or
            damages arising from breaches of the Agreement or negligence.
            iNextLabs' liability is limited to the charges paid during the
            Customer's last billing cycle. Neither Party is liable for indirect
            or consequential damages, such as loss of profits or business
            interruption.
          </p>
          <br />
          <h3 className="paragraphTitle">9. Warranties</h3>
          <p className="para">
            iNextLabs provides its services on an "as-is" basis without express
            or implied warranties. There is no guarantee that services will be
            uninterrupted, secure, or error-free. The Customer uses the services
            at their own risk.
          </p>
          <br />
          <h3 className="paragraphTitle">
            10. Governing Law and Dispute Resolution
          </h3>
          <p className="para">
            This Agreement remains in effect for the duration of the
            subscription and renews automatically unless terminated. Either
            Party may terminate with one month’s notice. Upon termination, the
            Customer must return any confidential information and uninstall
            iNextLabs' software.
          </p>
          <br />

          <h3 className="paragraphTitle">11. Miscellaneous</h3>
          <p className="para">
            <ol>
              <li className="para">
                <p>
                  <b>Force Majeure: </b> Neither Party will be held liable for
                  delays or failures due to events beyond reasonable control,
                  such as natural disasters or force majeure.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Modifications: </b> This Agreement can only be modified
                  with the written consent of both Parties.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Notices: </b>Notices between the Parties must be in
                  writing. This Agreement constitutes the entire understanding
                  between the Parties and supersedes any prior agreements.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Early Termination: </b> Early termination fees may apply as
                  outlined in the subscription plan to compensate iNextLabs for
                  the loss of anticipated revenue.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Changes to Subscription Plans: </b>iNextLabs reserves the
                  right to modify its subscription plans. Customers will be
                  notified of any material changes at least 30 days in advance.
                </p>
              </li>
            </ol>
          </p>
          <br />
          <h3 className="paragraphTitle">12. Confidentiality and Security</h3>
          <p className="para">
            <ol>
              <li className="para">
                <p>
                  iNextLabs is committed to maintaining the confidentiality and
                  security of all customer data. iNextLabs shall implement and
                  maintain comprehensive administrative, technical, and physical
                  safeguards designed to protect customer data from unauthorized
                  access, use, alteration, or disclosure. All customer data
                  shall be encrypted both in transit and at rest, and access to
                  such data shall be restricted solely to authorized personnel,
                  in accordance with the principle of least privilege.{" "}
                </p>
              </li>
              <li className="para">
                <p>
                  iNextLabs agrees to treat all customer data as confidential
                  and shall not disclose such data to any third party, except as
                  necessary to provide the services, to comply with legal
                  obligations, or with the express written consent of the
                  customer. In the event of a data breach or any unauthorized
                  access to customer data, iNextLabs shall promptly notify the
                  affected customer and shall take appropriate measures to
                  mitigate any potential harm
                </p>
              </li>
              <li className="para">
                <p>
                  Additionally, iNextLabs shall comply with all applicable
                  industry standards and regulations to ensure that the security
                  and confidentiality of customer data are upheld to the highest
                  standards. Customers are advised to review and understand the
                  security features and settings available within iNextLabs’
                  services to further safeguard their data.
                </p>
              </li>
            </ol>
          </p>
          <br />
          <h3 className="paragraphTitle">13. Subscription</h3>
          <p className="para">
            iNextLabs offers its generative-AI solutions through a
            subscription-based model, with various subscription plans tailored
            to meet different customer needs. By subscribing to our services,
            the customer agrees to the terms and conditions associated with the
            selected subscription plan, including but not limited to the payment
            of fees, the duration of the subscription, and any renewal terms.
            <ol>
              <li className="para">
                <p>
                  <b>Subscription Term: </b> The subscription term will begin on
                  the date the customer first subscribes to the service and will
                  continue for the duration specified in the chosen subscription
                  plan. Unless otherwise specified, subscriptions will
                  automatically renew at the end of the term for an additional
                  period of the same length, unless the customer provides notice
                  of non-renewal at least 30 days before the end of the current
                  term.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Payment: </b> The customer agrees to pay all fees
                  associated with the selected subscription plan. Payments are
                  due in advance of each subscription period, and iNextLabs
                  reserves the right to suspend or terminate the customer’s
                  access to the services if payment is not received by the due
                  date. All fees are non-refundable, except as otherwise
                  provided in these Terms of Use or as required by law.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Upgrades and Downgrade: </b>Customers may upgrade or
                  downgrade their subscription plan at any time, subject to the
                  availability of such plans. Any changes to the subscription
                  plan will take effect at the beginning of the next billing
                  cycle unless otherwise agreed upon by both parties. Upgrading
                  to a higher plan may result in additional fees, while
                  downgrading may result in a loss of certain features or
                  service levels.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Termination: </b> Either party may terminate the
                  subscription at any time, with or without cause, by providing
                  written notice to the other party. If the customer terminates
                  the subscription, they will continue to have access to the
                  services until the end of the current billing period, after
                  which access will be terminated. If iNextLabs terminates the
                  subscription for reasons other than the customer’s breach of
                  these Terms of Use, the customer will be entitled to a
                  pro-rata refund of any prepaid fees for the remaining portion
                  of the subscription term.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Early Termination: </b>If the customer terminates the
                  subscription before the end of the agreed subscription term,
                  early termination fees may apply as outlined in the
                  subscription plan or agreement. These fees are intended to
                  compensate iNextLabs for the loss of anticipated revenue and
                  are not intended as a penalty.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Changes to Subscription Plans: </b> iNextLabs reserves the
                  right to modify its subscription plans, including the fees,
                  features, and service levels, at any time. Customers will be
                  notified of any material changes at least 30 days before they
                  take effect. If the customer does not agree to the changes,
                  they may cancel their subscription before the changes come
                  into effect without penalty.
                </p>
              </li>
            </ol>
          </p>
          <br />
          <h3 className="paragraphTitle">14. Service Level Agreement (SLA)</h3>
          <p className="para">
            iNextLabs is committed to providing reliable and high-quality
            services to its customers. This Service Level Agreement (SLA)
            outlines the performance standards and service commitments that
            iNextLabs guarantees for its generative-AI solutions. This SLA forms
            part of the Terms of Use and applies to all customers who have
            subscribed to our services.
          </p>
          <br />
          <h3 className="paragraphTitle">15.Uptime Commitment </h3>
          <p className="para">
            iNextLabs guarantees a minimum of 99.5% uptime availability for its
            generative-AI solutions, calculated on a monthly basis. Uptime is
            defined as the time during which the services are accessible and
            operational, excluding scheduled maintenance, emergency maintenance,
            and events outside of iNextLabs’ control, such as issues with
            third-party providers including, but not limited to, Microsoft
            Azure, WhatsApp, and other integrated services
          </p>
          <br />
          <h3 className="paragraphTitle">16. Support Response Times </h3>
          <p className="para">
            iNextLabs offers tiered support services based on the customer’s
            subscription plan. For standard support, the support hours will be
            Monday-Friday 9 AM -6 PM except public holiday. Response times for support
            requests are as follows:
            <ol>
              <li className="para">
                <p>
                  <b>Standard Support: </b>Response within 8 business hours for
                  critical issues, 24 business hours for non-critical issues.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Premium Support: </b>Response within 4 business hours for
                  critical issues, 12 business hours for non-critical issues.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Enterprise Support: </b> Response within 1 business hour
                  for critical issues, 4 business hours for non-critical issues.
                </p>
              </li>
            </ol>
            Critical issues are defined as those causing a complete loss of
            service or severe degradation of service performance. Non-critical
            issues are defined as those causing partial loss of service or minor
            performance degradation
          </p>
          <br />
          <h3 className="paragraphTitle">17. Incident Management</h3>
          <p className="para">
            iNextLabs will proactively monitor the services to detect and
            respond to incidents. In the event of an incident affecting the
            service, iNextLabs will take the following actions:
            <ol>
              <li className="para">
                <p>
                  <b>Incident Detection: </b>Prompt detection and logging of the
                  incident.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Incident Response: </b>Immediate assignment of resources to
                  diagnose and resolve the incident.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Incident Communication: </b>Regular updates to affected
                  customers, including an initial notification within 60 minutes
                  of incident detection and ongoing updates until resolution.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Incident Resolution: </b>Efforts to restore the service to
                  normal operation as quickly as possible
                </p>
              </li>
            </ol>
            Critical issues are defined as those causing a complete loss of
            service or severe degradation of service performance. Non-critical
            issues are defined as those causing partial loss of service or minor
            performance degradation
          </p>
          <br />
          <h3 className="paragraphTitle">18. Maintenance</h3>
          <p className="para">
            iNextLabs will perform routine maintenance to ensure the continued
            performance, security, and reliability of its services. Customers
            will be notified at least 48 hours in advance of any scheduled
            maintenance that may impact service availability. Emergency
            maintenance may be performed without prior notice if necessary to
            address critical security vulnerabilities or other urgent issues.
            <ol>
              <li className="para">
                <p>
                  <b>Scheduled Maintenance: </b>Typically conducted during
                  off-peak hours to minimize disruption to customers. Scheduled
                  maintenance periods are not counted as downtime for the
                  purposes of uptime calculations.
                </p>
              </li>
              <li className="para">
                <p>
                  <b>Emergency Maintenance: </b>Conducted as needed to address
                  urgent issues that could affect service stability or security.
                  iNextLabs will make every effort to minimize the impact on
                  customers
                </p>
              </li>
            </ol>
          </p>
          <br />
          <h3 className="paragraphTitle">19. Exclusions</h3>
          <p className="para">
            This SLA does not apply to any performance issues caused by:
            <ol>
              <li className="para">
                <p>
                  Factors outside of iNextLabs’ reasonable control, including
                  issues with third-party providers such as Microsoft Azure,
                  WhatsApp, or other integrated services, as well as natural
                  disasters, acts of terrorism, or other force majeure events.
                </p>
              </li>
              <li className="para">
                <p>
                  Customer’s misuse of the services or failure to comply with
                  the Terms of Use.
                </p>
              </li>
              <li className="para">
                <p>
                  Third-party services, hardware, or software not provided by
                  iNextLabs.
                </p>
              </li>
              <li className="para">
                <p>Scheduled maintenance, as described above.</p>
              </li>
            </ol>
          </p>
          <br />
          <h3 className="paragraphTitle">20.Backup Availability </h3>
          <p className="para">
            Backups of customer data are available upon request. Customers may
            request backups by contacting iNextLabs’ support team. iNextLabs
            shall provide such backups in a timely manner, subject to the terms
            and conditions agreed upon in the support agreement. iNextLabs does
            not guarantee the availability of backups beyond the retention
            period specified in the applicable service agreement and shall not
            be held liable for any loss of data that occurs outside of the
            agreed retention period.
          </p>
          <br />
          <p className="para">
            For any questions or concerns, please contact us at{" "}
            <a href="mailto:accounts@inextlabs.com">accounts@inextlabs.com</a>
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TermsOfService;
