import {
  MdInsights,
  MdIntegrationInstructions,
  MdSecurity,
} from "react-icons/md";
import { FaHandsHelping } from "react-icons/fa";

export const informationTechnologyContent = {
  banner: {
    primaryButton: {
      text: "Start your free trial",
      url: "/contact/#contactform",
    },
    description:
      "In today's fast-paced digital landscape, staying competitive necessitates solutions that streamline complex processes. Our AI solution is engineered to consolidate IT knowledge and automatically resolve complex issues, enabling your IT team to dedicate their efforts to strategic projects.",
    id: "1",
    imageURL: "/images/solutions/it/banner.png",
    background: "/images/solutions/healthcare/banner.jpg",
    sectionTile: "",
    title: "Upgrade your IT operations with autonomous AI",
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/solutions/it/streamline_it_support.png",
      pointer: "/images/icons/pointer.svg",
      title: "Streamline repetitive IT support tasks",
      descriptionOne: `<strong>Instant resolution:</strong> Automatically resolves common IT issues, like password resets, ensuring instant employee support.`,
      descriptionTwo: `<strong>Effortless software provisioning:</strong> Efficiently provisions software, manages access, reduces delays, boosts overall productivity.`,
      descriptionThree: `<strong>Automated troubleshooting:</strong> Resolves frequent troubleshooting issues, letting your IT team focus on complex challenges.`,
    },
    {
      id: "2",
      model: "model1",
      imageURL: "/images/solutions/it/consolidate_it_knowledge.png",
      pointer: "/images/icons/pointer.svg",
      title: "Consolidate IT knowledge across enterprise",
      descriptionOne:
        "<strong>Unified access:</strong> Integrates with all major knowledge bases, centralizing information from diverse applications.",
      descriptionTwo:
        "<strong>Accurate insights:</strong> Ensures employees receive the most relevant and precise information available.",
      descriptionThree:
        "<strong>Streamlined operations:</strong> Enhances efficiency by consolidating IT knowledge into a single, accessible source.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/solutions/it/accelerate_ticket_resolution.png",
      pointer: "/images/icons/pointer.svg",
      title: "Accelerate ticket resolution and optimize resources",
      descriptionOne:
        "<strong>Streamline complex ticket handling:</strong> Direct tickets to experts, help agents solve problems, and keep communication smooth for faster resolutions.",
      descriptionTwo:
        "<strong>Efficient license management:</strong> Revoke unused licenses and monitor software usage to optimize IT resources.",
      descriptionThree:
        "<strong>Boost IT resource utilization:</strong> Automate tasks to optimize resource management and free IT for strategy.",
    },
  ],

  stats: {
    title: "Experience the impact of autonomous AI",
    description:
      "Transform your IT operations with our AI-driven solutions, boosting productivity and reducing operational costs.",
    content: [
      {
        id: "1",
        title: "Reduction in IT tickets",
        count: 70,
      },
      {
        id: "2",
        title: "Increase in service desk productivity",
        count: 75,
      },
      {
        id: "3",
        title: "Accuracy in ticket routing",
        count: 98,
      },
      {
        id: "4",
        title: "Reduction in resolution time",
        count: 40,
      },
    ],
  },
  whyChooseUs: {
    title: "Why choose us",
    tagline: "It's more than just these reasons",
    content: [
      {
        id: "1",
        title: "Unmatched security",
        tagline:
          "We ensure robust encryption, compliance, and continuous data monitoring.",
        icon: <MdSecurity size={20} />, // Icon representing security
      },
      {
        id: "2",
        title: "Seamless integration",
        tagline:
          "Easily integrates with IT systems, offering quick deployment, and simplicity.",
        icon: <MdIntegrationInstructions size={20} />, // Icon representing integration
      },
      {
        id: "3",
        title: "Data-driven insights",
        tagline:
          "Our AI solutions provide real-time analytics for informed, data-driven decisions.",
        icon: <MdInsights size={20} />, // Icon representing insights
      },
      {
        id: "4",
        title: "Comprehensive support",
        tagline:
          "Enhance communication with humanized, multi-channel, multilingual support for teams.",
        icon: <FaHandsHelping size={20} />, // Icon representing comprehensive support
      },
    ],
  },
  FAQ: [
    {
      id: "1",
      question: "How is AI used in the IT industry?",
      answer:
        "AI plays a diverse role in the IT industry by automating routine tasks, enhancing cybersecurity, optimizing IT operations, and providing data-driven insights. It is increasingly integrated into software development, IT infrastructure management, and data analysis to improve efficiency and decision-making.",
    },
    {
      id: "2",
      question: "How can AI assist IT professionals?",
      answer:
        "AI-powered tools enhance task management, streamline scheduling, and automate routine processes. This allows IT professionals to focus on strategic and creative tasks, boosting overall productivity and enabling faster, more efficient operations.",
    },
    {
      id: "3",
      question: "How will AI transform the IT industry?",
      answer:
        "AI will reshape the IT industry by enabling strategic resource allocation, shifting personnel to higher-value tasks, and fostering sustainable growth. As companies embrace AI-driven strategies, IT services will increasingly rely on AI's capabilities to process and analyze vast amounts of data.",
    },
    {
      id: "4",
      question:
        "What are the advantages of automating IT support and services?",
      answer:
        "Automating IT support enhances operational efficiency by managing repetitive tasks and accelerating issue resolution. It improves user satisfaction with quick, accurate responses, and enables IT staff to focus on strategic, high-priority projects instead of routine support requests.",
    },
  ],

  meta: {
    title: "AI solutions for IT operations - inextlabs",
    description:
      "Explore AI-powered solutions for IT operations at inext labs. Enhance IT support, resource management, and operations with advanced technology.",
    locale: "en_us",
    siteName: "inextlabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
