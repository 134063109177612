import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({ basename: "" });
var reactPlugin = new ReactPlugin();

var appInsights = new ApplicationInsights({
  config: {
    connectionString:
      "InstrumentationKey=98f881b1-e0c4-4d02-9540-34abf8a844df;IngestionEndpoint=https://southeastasia-1.in.applicationinsights.azure.com/;LiveEndpoint=https://southeastasia.livediagnostics.monitor.azure.com/;ApplicationId=2efc8194-9c30-4c04-92ea-b4b503187725",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
