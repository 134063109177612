import React from "react";
import Header from "../header/Header";
import Filler from "../header/Filler";
import HomeBanner from "../banner/HomeBanner";
import Footer from "../footer/Footer";
import ResourcesItem from "./ResourcesItem";
import { resourcesContent } from "./resourcesContent";
import { blogsContent } from "./blogsContent";
import Meta from "../Meta";
import ResouresBanner from "./banner/ResourcesBanner";

const Blogs = () => {
  const blog = resourcesContent.resourceItems;
  const blogItems = blog.filter((item) => item.type === "Blog");
  const blogsBannerContent = blogsContent;
  return (
    <>
      <Meta
        title={blogsContent.meta.title}
        description={blogsContent.meta.description}
        image={blogsContent.meta.image}
        locale={blogsContent.meta.locale}
        siteName={blogsContent.meta.siteName}
        type={blogsContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <ResouresBanner content={blogsBannerContent.banner} />
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="resourcesListContainer">
            {blogItems.map((blogs, index) => {
              return <ResourcesItem key={index} content={blogs} />;
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
