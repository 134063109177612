import React from "react";
import Meta from "../../Meta";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import ProductsBanner from "../banner/ProductsBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProps from "../../swimlinesection/ValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import AccordionSection from "../../accordion/Accordion";
import Footer from "../../footer/Footer";
import { securityContent } from "./securityContent";

const Security = () => {
  return (
    <div>
      <Meta
        title={securityContent.meta.title}
        description={securityContent.meta.description}
        image={securityContent.meta.image}
        locale={securityContent.meta.locale}
        siteName={securityContent.meta.siteName}
        type={securityContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <ProductsBanner content={securityContent.banner} />
      <CounterStats content={securityContent.stats} />
      <ValueProps content={securityContent.valueProps} />
      <ValueProposition content={securityContent.whyChooseUs} />
      <DiscoveryWorkshop />
      <AccordionSection content={securityContent.FAQ} />
      <Footer />
    </div>
  );
};

export default Security;
