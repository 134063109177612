export const blogsContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/sign-up",
    },
    description:
      "Explore our collection of enlightening blogs and captivating technical articles that delve into cutting-edge topics like Generative AI, Large Language Models, and Conversational AI. Discover how these transformative technologies are reshaping businesses across industries, from enhancing customer interactions to revolutionizing data analysis.",
    id: "1",
    imageURL: "/images/resources/library/pexels-ravi-kant-4533596.jpg",
    subTitle: "",
    title: "We track the latest trends and innovations",
  },

  meta: {
    title: "Insights and Updates - iNextLabs Blog",
    description:
      "Explore the latest insights, trends, and updates on AI technology at the Inext Labs blog. Stay informed and inspired with our expert articles.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
