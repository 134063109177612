import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "../../../modal/Modal";
import { Button } from "@material-tailwind/react";

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [response, SetResponse] = useState(null);
  const [errorMessage, SetErrorMessage] = useState(null);

  const closeModal = () => {
    setShowModal(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const sendEmail = async (formValues) => {
    setShowModal(true);
    setLoading(true);
    try {
      const response = await fetch("/contact", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: { "content-type": "application/json" },
      });
      const data = await response.json();

      setLoading(false);

      if (response.ok) {
        SetResponse(data.message);
        setShowResult(true);
        setShowFailure(false);
      } else {
        const data = await response.text();
        SetErrorMessage(data.message);
        setShowFailure(true);
        setShowResult(false);
      }
    } catch (error) {
      setLoading(false);
      setShowFailure(true);
      SetErrorMessage("The server encountered an unexpected condition that prevented it from fulfilling the request.");
      setShowResult(false);
    }
    reset(); // Reset the form after successful submission
  };

  return (
    <div id="contact-form-container">
      <form id="contact-form" onSubmit={handleSubmit(sendEmail)}>
        <div className="contact-form-row-container">
          <div className="contact-form-name">
            <input
              id="name"
              name="name"
              className="row-input"
              type="text"
              placeholder="Name"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <span className="error-message">Please enter your name</span>
            )}
          </div>
          <div className="contact-form-experience">
            <input
              id="company"
              name="company"
              className="row-input"
              type="text"
              placeholder="Company"
              {...register("company")}
            />
          </div>
        </div>

        <div className="contact-form-row-container">
          <div className="contact-email-name">
            <input
              id="email"
              name="email"
              className="row-input"
              type="email"
              placeholder="Email address"
              {...register("email", {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
            />
            {errors.email && errors.email.type === "required" && (
              <span className="error-message">
                Please enter your email address
              </span>
            )}
            {errors.email && errors.email.type === "pattern" && (
              <span className="error-message">
                Please enter a valid email address
              </span>
            )}
          </div>
          <div className="contact-form-phone">
            <input
              id="phone"
              name="phone_number"
              className="row-input"
              type="number"
              placeholder="Phone number"
              {...register("phone_number", { required: true })}
            />
            {errors.phone_number && (
              <span className="error-message">
                Please enter your phone number
              </span>
            )}
          </div>
        </div>
        <textarea
          id="message"
          name="message"
          placeholder="Message"
          className="row-input"
          {...register("message")}
        ></textarea>
        <div className="contact-form-submit-button">
          <Button id="contact-form-submit" className="button" type="submit">
            Submit
          </Button>
        </div>
      </form>

      {showModal && (
        <Modal
          loading={loading}
          showResult={showResult}
          showFailure={showFailure}
          closeModal={closeModal}
          response={response}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

export default ContactForm;