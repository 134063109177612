import React from "react";
import { BiTimeFive } from "react-icons/bi";
import { FaBlog } from "react-icons/fa";
import { BsCalendarEvent } from "react-icons/bs";
function NewsBanner(props) {
  return (
    <>
      <div className="outerContainer resource-banner">
        <div className="innerContainer">
          <div className="resource-banner-title">{props.content.title}</div>
          <div className="resource-banner-info">
            <div className="resource-banner-info-type">
              <BsCalendarEvent size={12} />

              {props.content.type}
            </div>
            <div className="resource-banner-info-date">
              <BiTimeFive size={15} />
              <span>{props.content.date}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsBanner;
