import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Modal from "../modal/Modal";
import { CountriesContent } from "../Countries";
import { Checkbox } from "antd";
import { Link } from "react-router-dom";
import { Button } from "@material-tailwind/react";

const SignUp = () => {
  const [showForm, setShowForm] = useState(true);
  const token = window.location.search.split("?token=")[1];

  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [response, SetResponse] = useState(null);
  const [errorMessage, SetErrorMessage] = useState(null);
  const [terms, setTerms] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formValues) => {
    if (!terms) {
      // alert("Please accept the terms before submitting the form.");
      return;
    }
    setShowModal(true);
    setLoading(true);
    try {
      const response = await fetch("/signup", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: { "content-type": "application/json" },
      });
      const data = await response.json();

      setLoading(false);

      if (response.ok) {
        SetResponse(data.message);
        setShowResult(true);
        setShowFailure(false);
      } else {
        SetErrorMessage(data.message);
        setShowFailure(true);
        setShowResult(false);
      }
    } catch (error) {
      setLoading(false);
      setShowFailure(true);
      SetErrorMessage(
        " The server encountered an unexpected condition that prevented it from fulfilling the request."
      );
      setShowResult(false);
    }
  };

  const [customerDetails, setcustomerDetails] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.inextlabs.ai/marketplace/subscriptions/subscription",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Api-Key": "95e47b815140475798e3220906d74109",
            },
            body: JSON.stringify({
              token: token,
            }),
          }
        );

        if (response.status === 502) {
          setShowForm(true);
          setcustomerDetails(
            "An internal server error occurred. Please try again later."
          );
        } else {
          const customerDetails = await response.json();
          setcustomerDetails(customerDetails.subscription);
          setShowForm(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="sign-up-page-outer-container">
        <div className="sign-up-page-inner-container">
          <div className="sign-up-page-container">
            {showForm === true ? (
              <div className="sign-up-form-outer-container">
                <div className="sign-up-form-inner-container">
                  <div className="sign-up-form-header">
                    <h4 className="sign-up-form-header-title">
                      Start Your Free Trial
                    </h4>
                    <p className="sign-up-form-header-description">
                      Enter your Signup details below
                    </p>
                  </div>
                  <form id="sign-up" onSubmit={handleSubmit(onSubmit)}>
                    <div className="signup-form-row-container">
                      <div className="sign-up-name form-field">
                        <label htmlFor="name">Name</label>
                        <Controller
                          control={control}
                          name="name"
                          defaultValue=""
                          rules={{ required: "Name is required" }}
                          render={({ field }) => (
                            <input
                              {...field}
                              id="name"
                              className="signup-row-input"
                              type="text"
                              placeholder="John Doe"
                            />
                          )}
                        />
                        {errors.name && (
                          <span className="error-message">
                            {errors.name.message}
                          </span>
                        )}
                      </div>

                      <div className="sign-up-email form-field">
                        <label htmlFor="email_id">Business Email</label>
                        <Controller
                          control={control}
                          name="email_id"
                          defaultValue=""
                          rules={{
                            required: "Business email is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Invalid email address",
                            },
                          }}
                          render={({ field }) => (
                            <input
                              {...field}
                              id="email_id"
                              className="signup-row-input"
                              type="email"
                              placeholder="john09@example.com"
                            />
                          )}
                        />
                        {errors.email_id && (
                          <span className="error-message">
                            {errors.email_id.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="signup-form-row-container">
                      <div className="sign-up-form-phone form-field">
                        <label htmlFor="phone_number">Phone Number</label>
                        <Controller
                          control={control}
                          name="phone_number"
                          defaultValue=""
                          // rules={{
                          //   required: "Phone number is required",
                          //   pattern: {
                          //     value: /^[0-9]*$/,
                          //     message: "Invalid phone number",
                          //   },
                          // }}
                          render={({ field }) => (
                            <input
                              {...field}
                              id="phone_number"
                              className="signup-row-input"
                              type="text"
                              placeholder="+6566009854"
                            />
                          )}
                        />
                        {/* {errors.phone_number && (
                          <span className="error-message">
                            {errors.phone_number.message}
                          </span>
                        )} */}
                      </div>
                      <div className="sign-up-form-company form-field">
                        <label htmlFor="company">Company</label>
                        <Controller
                          control={control}
                          name="company"
                          defaultValue=""
                          rules={{ required: "Company is required" }}
                          render={({ field }) => (
                            <input
                              {...field}
                              id="company"
                              className="signup-row-input"
                              type="text"
                              placeholder="Company"
                            />
                          )}
                        />
                        {errors.company && (
                          <span className="error-message">
                            {errors.company.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="signup-form-row-container">
                      <div className="sign-up-form-country form-field">
                        <label htmlFor="country">Country</label>
                        <Controller
                          control={control}
                          name="country"
                          defaultValue="none"
                          rules={{ required: "Country is required" }}
                          render={({ field }) => (
                            <select {...field} className="signup-country">
                              <option value="none">Select Country</option>
                              {CountriesContent.map((country) => (
                                <option value={country.name}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          )}
                        />
                        {errors.country && (
                          <span className="error-message">
                            {errors.country.message}
                          </span>
                        )}
                      </div>
                      <div className="sign-up-form-user-source form-field">
                        <label htmlFor="country">
                          How did you hear about us?
                        </label>
                        <Controller
                          control={control}
                          name="user_source"
                          defaultValue="none"
                          rules={{ required: "Category is required" }}
                          render={({ field }) => (
                            <select {...field} className="signup-row-input">
                              <option value="none">Select</option>
                              <option value="Social Media">Social Media</option>
                              <option value="Friend">Refferal</option>
                              <option value="Internet Search">
                                Internet Search
                              </option>
                              <option value="Advertisement">
                                Advertisement
                              </option>
                              <option value="Other">Other</option>
                            </select>
                          )}
                        />
                        {errors.country && (
                          <span className="error-message">
                            {errors.country.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="sign-up-form-phone form-field">
                      <Controller
                        control={control}
                        name="terms"
                        defaultValue=""
                        render={() => (
                          <Checkbox
                            className="signup-checkbox"
                            onClick={(e) => {
                              setTerms(e.target.checked);
                            }}
                          >
                            I have read the{" "}
                            <Link to="/privacy-policy" target="_blank">
                              Privacy Policy
                            </Link>{" "}
                            and consent to processing of personal data by
                            inextlabs.ai
                          </Checkbox>
                        )}
                      />
                    </div>

                    <div className="sign-up-form-submit-button">
                      <Button
                        id="sign-up-form-submit"
                        className="button"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div className="subscriber-details-outer-container">
                <div className="subscriber-details-inner-container">
                  <h2 className="subscriber-details-container-title">
                    Subscriber Details
                  </h2>
                  <div className="subscriber-details">
                    <div className="subscriber-details-pair">
                      <div className="subscriber-key">Customer Email:</div>
                      <div className="subscriber-value">
                        {customerDetails.purchaser.emailId}
                      </div>
                    </div>
                    <div className="subscriber-details-pair">
                      <div className="subscriber-key">ID:</div>
                      <div className="subscriber-value">
                        {customerDetails.id}
                      </div>
                    </div>
                    <div className="subscriber-details-pair">
                      <div className="subscriber-key">Name:</div>
                      <div className="subscriber-value">
                        {customerDetails.name}
                      </div>
                    </div>
                    <div className="subscriber-details-pair">
                      <div className="subscriber-key">Plan:</div>
                      <div className="subscriber-value">
                        {customerDetails.planId}
                      </div>
                    </div>
                    <div className="subscriber-details-pair">
                      <div className="subscriber-key">Purchaser Email:</div>
                      <div className="subscriber-value">
                        {customerDetails.purchaser.emailId}
                      </div>
                    </div>
                    <div className="subscriber-details-pair">
                      <div className="subscriber-key">Azure Tenant ID:</div>
                      <div className="subscriber-value">
                        {customerDetails.purchaser.tenantId}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="subscriber-details-footer">
                  <Button className="button">Subscribe</Button>
                </div>
              </div>
            )}
            <div className="sign-up-image-container">
              <img src="/images/general/signuphero.jpg" alt="signup" />
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          loading={loading}
          showResult={showResult}
          showFailure={showFailure}
          closeModal={closeModal}
          response={response}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};

export default SignUp;
