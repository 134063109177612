import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import SignUpModal from "../../modal/SignUpModal";
import { Button } from "@material-tailwind/react";
import { freeTrailClickFunction } from "../../../app-insights/freeTrailClickFunction";
import { Link } from "react-router-dom";

const SolutionBanner = (props) => {
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="solution-banner-outer-container outerContainer">
        <div className="solution-banner-inner-bg">
          <div className="solution-banner-inner-container">
            <div className="solution-banner-content">
              <h1
                className="solution-banner-title bannerTitle left"
                data-aos="fade-right"
                data-aos-duration="500"
              >
                {props.content.title}
              </h1>
              <p
                className="solution-banner-description para"
                data-aos="fade-right"
                data-aos-duration="700"
              >
                {props.content.description}
              </p>
              <div
                className="solution-banner-button"
                data-aos="fade-right"
                data-aos-duration="900"
              >
                <div>
                  <Button
                    className="button solution-banner-primaty-button"
                    onClick={() => {
                      setIsOpen(true);
                      freeTrailClickFunction();
                    }}
                  >
                    {props.content.primaryButton.text}
                  </Button>
                  <p className="credit-card-text center">
                    *No credit card required
                  </p>
                </div>
                {props.content.secondaryButton && (
                  <div>
                    <Link
                      to={props.content.secondaryButton.url}
                      target="_blank"
                    >
                      <Button className="button bannerSecondaryButton">
                        {props.content.secondaryButton.text}
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="solution-byIndustry-banner-image">
              <img src={props.content.imageURL} alt="" />
            </div>
            {/* {props.content.solutionType === "byIndustry" ? (
              <div className="solution-byIndustry-banner-image">
                <img src={props.content.imageURL} alt="" />
              </div>
            ) : (
              <div className="solution-banner-image">
                <div className="solution-banner-image-col1">
                  <div className="solution-banner-image-col-image">
                    <img
                      src={props.content.image.img1}
                      alt="banner"
                      className="bannerImage"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    />
                  </div>
                </div>
                <div className="solution-banner-image-col2">
                  <div className="solution-banner-image-col-image">
                    <img
                      src={props.content.image.img2}
                      alt="banner"
                      className="bannerImage"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    />
                    <img
                      src={props.content.image.img3}
                      alt="banner"
                      className="bannerImage"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    />
                  </div>
                </div>
                <div className="solution-banner-image-col3">
                  <div className="solution-banner-image-col-image">
                    <img
                      src={props.content.image.img4}
                      alt="banner"
                      className="bannerImage"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    />
                    <img
                      src={props.content.image.img5}
                      alt="banner"
                      className="bannerImage"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    />
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </>
  );
};

export default SolutionBanner;
