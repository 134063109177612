import Divider from "../../../Divider";
import React from "react";

const AboutStatement = () => {
  return (
    <div>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="about-statement-container">
            <h2 className="about-statement-container-title">About</h2>
            <div className="about-statement-container-description">
              <p>
                <strong>iNextLabs</strong> is a Gen AI-focused company dedicated
                to making Generative AI accessible and transformative for
                businesses of all sizes. Founded in 2020 and headquartered in
                Singapore, with offices in Malaysia, the USA, and India, we
                offer productized AI solutions tailored to various industries.
              </p>
              <p>
                Our expertise spans Virtual inFlow Assistants, Data Visualization
                Tools, Advanced Analytics, and Process Optimization. By
                delivering solutions tailored to specific use cases across HR,
                customer support, IT, sales, and more, we redefine the AI
                landscape. We empower companies to harness the full potential of
                Generative AI, driving unprecedented growth and efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="outerContainer">
        <h2 className="about-missionandvision-section-title sectionTitle">
          Our Vision and Mission
        </h2>
        <Divider />
        <div className="innerContainer">
          <div className="about-statement-container">
            <h2 className="about-statement-container-title">Mission</h2>
            <div className="about-statement-container-description">
              <p>
                At iNextLabs, our mission is to empower businesses to harness
                the full potential of AI to redefine customer experiences. We
                are committed to leveraging advanced AI solutions to optimize
                marketing, support, and operations, ultimately enhancing
                engagement, streamlining processes, and driving growth. Through
                innovative AI tools, we aim to make interactions more meaningful
                and efficient, boosting ROI, customer satisfaction, and
                operational effectiveness.
              </p>
            </div>
          </div>
        </div>

        <div className="innerContainer">
          <div className="about-statement-container">
            <h2 className="about-statement-container-title">Vision</h2>
            <div className="about-statement-container-description">
              <p>
                Our vision is to be the leading partner in AI technology,
                setting new standards for personalized and efficient service. We
                strive to drive the seamless integration of AI into business
                operations across industries, continuously advancing AI
                solutions to help businesses thrive. Our goal is to remain at
                the forefront of AI innovation, delivering exceptional value to
                our clients and transforming the way businesses interact with
                their customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutStatement;
