import React from "react";
import Divider from "../../../Divider";

const TeamCard = (props) => {
  const TeamsCardContent = props.content;

  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <h2 className="about-teams-section-title sectionTitle">Meet the team</h2>
          <Divider />
          <div className="about-teams-card-container">
            {TeamsCardContent.map((ourPartner, index) => (
              <div className="about-teams-card" key={index}>
                <div className="about-teams-card-cover">
                  <img
                    className="about-teams-card-avatar"
                    src={ourPartner.avatar}
                    alt="Teams avatar"
                  />
                </div>
                <p className="about-teams-card-fullname">{ourPartner.name}</p>
                <h4 className="about-teams-card-jobtitle">
                  {ourPartner.jobTitle}
                </h4>
                
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamCard;
