import React, { useEffect } from "react";
import { MdDone } from "react-icons/md";
import "aos/dist/aos.css";
import AOS from "aos";

const PartnersFeatures = (props) => {
  const featuresContent = props.content;
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="outerContainer partners-features-outer-container">
        <div className="partners-features-inner-container">
          <div className="partners-features-container">
            <div className="partners-features-header">
              <div className="partners-features-header-image">
                <img
                  src="/images/general/focused-leader-woman.jpg"
                  alt="partner feature image"
                />
              </div>
            </div>

            <div className="partners-features-card-details">
              <h2 className="partners-features-main-title sectionTitle left">
                Why Partner with iNextlabs
              </h2>
              {featuresContent.map((content, index) => (
                <div
                  className="partners-features-card-details-content"
                  key={index}
                  data-aos="fade-up"
                >
                  <h3 className="partners-features-card-title">
                    <MdDone style={{ marginRight: "5px" }} />
                    {content.title}
                  </h3>
                  <p className="partners-features-card-description para">
                    {content.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnersFeatures;
