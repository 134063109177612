import React, { useState } from "react";
import { motion } from "framer-motion";

const BuisnessStatement = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="outerContainer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.svg
        id="fi_13652495"
        enable-background="new 0 0 64 64"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        width={100}
        initial={{
          y: "-25px",
          x: 100,
          scale: 1.2,
          opacity: 0.5,
        }}
        animate={{
          y: ["-20px", "-30px", "-20px"], // Animate up and down
          opacity: 0.9,
        }}
        transition={{
          duration: 3,
          repeat: Infinity, // Loop the animation
          repeatType: "reverse", // Alternate directions
          ease: "easeInOut",
        }}
        className="buisness-statement-svg"
      >
        <g fill="#ffe3df">
          <path d="m49.861 30.774c-1.584-.158-5.069-.158-5.069-2.376 0-3.009 4.435-6.969 11.563-11.246 2.217-1.426 6.812-3.643 6.812-6.653 0-2.376-1.743-4.118-5.386-3.643-3.485.475-8.871 2.851-15.998 8.87-7.445 6.178-14.414 16.474-14.414 26.611 0 10.296 6.969 20.592 18.216 20.592 9.028 0 16.79-6.811 16.79-16.157-.001-7.128-4.911-15.364-12.514-15.998z"></path>
          <path d="m27.594 26.32c-1.301-.68-2.728-1.125-4.268-1.253-1.584-.158-5.069-.158-5.069-2.376 0-3.009 4.435-6.969 11.563-11.246 2.218-1.426 6.811-3.643 6.811-6.653 0-2.376-1.743-4.118-5.386-3.643-3.485.475-8.871 2.851-15.998 8.87-7.444 6.178-14.414 16.474-14.414 26.612 0 10.296 6.969 20.592 18.216 20.592 2.644 0 5.178-.588 7.441-1.649-2.219-4.012-3.402-8.654-3.402-13.236.001-5.658 1.823-11.145 4.506-16.018z"></path>
        </g>
      </motion.svg>
      <div className="buisness-statement-container">
        <div className="buisness-statement-main-title">
          Go beyond chatbots, IVR, and RPA to transform your business with
          advanced AI solutions encompassing Gen-AI powered data analytics,
          document intelligence, and process optimization.
        </div>
        <div className="buisness-statement-image">
          <motion.img
            src="/images/home/Creativity-pana.svg"
            alt=""
            initial={{
              y: "50px",
              rotate: -16,
              scale: 1.2,
            }}
            animate={
              isHovered
                ? {
                    y: "40px",
                    scale: 1.3,
                    rotate: -18,
                  }
                : {
                    y: "50px",
                    scale: 1.2,
                    rotate: -16,
                  }
            }
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>
    </div>
  );
};

export default BuisnessStatement;
