import { Card } from "antd";
import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const ContactSupport = (props) => {
  const ContactSupport = props.content;
  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="contact-support-list-container">
            {ContactSupport.map((support, index) => (
              <Card className="contact-support-list-card" key={index}>
                
                <h4 className="contact-support-list-card-title paragraphTitle">
                  {support.title}
                </h4>
                <div className="contact-support-list-card-content para">
                  <p>{support.content}</p>
                </div>
                <div className="contact-support-list-card-link para">
                  <Link to="/">{support.link} </Link>
                  <BsArrowRight />
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSupport;
