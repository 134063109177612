import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "../../../modal/Modal";
import { Button } from "@material-tailwind/react";

const JobForm = ({job}) => {
  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [response, SetResponse] = useState(null);
  const [errorMessage, SetErrorMessage] = useState(null);

  const closeModal = () => {
    setShowModal(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (formValues) => {
    setShowModal(true);
    setLoading(true);

    const formData = new FormData();
    formData.append("name", formValues.name);
    formData.append("email", formValues.email);
    formData.append("phone", formValues.phone);
    formData.append("experience", formValues.experience);
    formData.append("position", formValues.position);
    formData.append("file", formValues.file[0]);

    if (formValues.comments) {
      formData.append("comments", formValues.comments);
    }

    try {
      const response = await fetch("/careerReg", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      setLoading(false);

      if (response.ok) {
        SetResponse(data.message);
        setShowResult(true);
        setShowFailure(false);
      } else {
        SetErrorMessage(data.message);
        setShowFailure(true);
        setShowResult(false);
      }
    } catch (error) {
      setLoading(false);
      setShowFailure(true);
      SetErrorMessage(
        "The server encountered an unexpected condition that prevented it from fulfilling the request."
      );
      setShowResult(false);
    }

    reset();
  };

  return (
    <div id="career-form-container">
      <h2 id="career-form-container-title">
        Let's Innovate Together
        <br /> <span style={{ color: "#f05742" }}>Come, Join us!</span>
      </h2>
      <form
        id="career-form"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <div className="career-form-row-container">
          <div className="career-form-name">
            <input
              id="name"
              className="row-input"
              type="text"
              placeholder="Full name"
              {...register("name", { required: "Full name is required" })}
            />
            {errors.name && (
              <span className="error-message">{errors.name.message}</span>
            )}
          </div>
          <div className="career-email-name">
            <input
              id="email"
              className="row-input"
              type="email"
              placeholder="Email address"
              {...register("email", {
                required: "Email address is required",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <span className="error-message">{errors.email.message}</span>
            )}
          </div>
        </div>
        <div className="career-form-row-container">
          <div className="career-form-phone">
            <input
              id="phone"
              className="row-input"
              type="text"
              placeholder="Phone number"
              {...register("phone", { required: "Phone number is required" })}
            />
            {errors.phone && (
              <span className="error-message">{errors.phone.message}</span>
            )}
          </div>
          <div className="career-form-experience">
            <input
              id="number"
              className="row-input"
              type="number"
              placeholder="Years of experience"
              {...register("experience", {
                required: true,
                max: 15,
              })}
            />
            {errors.experience && errors.experience.type === "required" && (
              <span className="error-message">This experience is required</span>
            )}
            {errors.experience && errors.experience.type === "max" && (
              <span className="error-message">
                Maximum experience allowed is 15 years
              </span>
            )}
          </div>
        </div>
        <div className="career-form-row-container">
          <div className="career-form-experience">
            <input
              id="job-position"
              className="row-input"
              type="text"
              placeholder="Job Position"
              value={job.jobTitle}
              {...register("position", {
                required: "Job position is required",
              })}
            />
            {errors.position && (
              <span className="error-message">{errors.position.message}</span>
            )}
          </div>

          <div className="career-form-upload">
            <input
              type="file"
              id="file-upload"
              accept=".pdf,.docx"
              {...register("file", {
                required: "Please upload your resume",
              })}
            />
            {errors.file && (
              <span className="error-message">{errors.file.message}</span>
            )}
          </div>
        </div>

        <textarea
          id="comments"
          placeholder="Enter other information here..."
          {...register("comments")}
          className="row-input"
        ></textarea>
        <div className="career-form-submit-button">
          <Button id="career-form-submit" className="button" type="submit">
            Submit
          </Button>
        </div>
      </form>
      {showModal && (
        <Modal
          loading={loading}
          showResult={showResult}
          showFailure={showFailure}
          closeModal={closeModal}
          response={response}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

export default JobForm;
