import React from "react";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import SolutionBanner from "../banner/SolutionBanner";
import { legalContent } from "./legalContent";
import Footer from "../../footer/Footer";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import InnerPageFeatures from "../../features/InnerPageFeatures";
import Clients from "../../clients/Clients";
import { homeContent } from "../../home/HomeContent";
import CounterStats from "../../stats-counter/Stats-counter";

const Legal = () => {
  return (
    <>
      <Header />
      <Filler />
      <SolutionBanner content={legalContent.banner} />
      <CounterStats
        content={legalContent.stats}
        title={"Elevate your legal operations with AI"}
      />
      <InnerPageValueProps content={legalContent.valueProps} />
      <InnerPageFeatures content={legalContent.innerFeatures} />
      <Clients content={homeContent.clients} />
      <Footer />
    </>
  );
};

export default Legal;
