import { FaBrain, FaExpandArrowsAlt, FaHandsHelping } from "react-icons/fa";
import {
  MdOutlineSecurity,
} from "react-icons/md";
import { SiTarget } from "react-icons/si";

export const FoundationModalContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Choose the right AI model for your enterprise needs with our expert guidance. Fine-tune models for accuracy and personalization while minimizing risks like hallucinations with Retrieval-Augmented Generation (RAG). Protect your AI with inFlow Shield, our advanced security layer that guards against vulnerabilities like jailbreaking. Trust iNextLabs for AI solutions that are precise, secure, and tailored to your business.",
    imageURL: "/images/products/foundation-model/banner.jpg",
    sectionTile: "",
    title: "Select the right AI foundation model for your business",
  },

  stats: {
    title: "Unlock the value of AI foundation models",
    description:
      "Transform efficiency, accuracy, and security with proven metrics",
    content: [
      {
        id: "1",
        title: "Reduction in AI development time",
        count: 60,
        source: "Gartner Research",
      },
      {
        id: "2",
        title: "Increase in model accuracy",
        count: 40,
        source: "McKinsey & Company",
      },
      {
        id: "3",
        title: "Decrease in AI implementation costs",
        count: 50,
        source: "Forrester Research",
      },
      {
        id: "4",
        title: "Lower security and compliance issues",
        count: 35,
        source: "IDC",
      },
    ],
  },

  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/products/foundation-model/tailored-ai.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Tailored AI for your enterprise",
      mainDescription:
        "Our foundation model allows businesses to select and fine-tune the right AI model to meet specific industry needs and business challenges. This level of customization ensures highly accurate, relevant, and personalized interactions that align with your goals, from enhancing customer experiences to optimizing internal workflows.",
    },
    {
      id: "2",
      model: "model1",
      imageURL: "/images/products/foundation-model/accuracy-performance.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Enhanced accuracy and performance",
      mainDescription:
        "Our foundation Model is built to seamlessly integrate into your business’s existing processes, reducing manual effort and increasing efficiency. By automating routine tasks and adapting AI capabilities to your unique workflow, your business can drive productivity gains and enhance performance. Whether it’s customer support, sales, or data processing, our AI solution ensures a smooth fit that maximizes impact while minimizing disruption.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/products/foundation-model/security-compliance.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Improved security and compliance",
      mainDescription:
        "Our foundation models are equipped to protect your AI systems from threats like jailbreak attempts, ensuring that your models operate ethically and comply with regulations. With this safeguard in place, your business can confidently deploy AI across sensitive or highly regulated industries, reducing risks while maintaining operational integrity.",
    },
    {
      id: "4",
      model: "model1",
      imageURL: "/images/products/foundation-model/future-proof-ai.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Future-proof your AI investments",
      mainDescription:
        "iNextLabs' Foundation Model is designed with scalability and flexibility in mind, allowing your AI solution to evolve as your business grows. As market demands shift and your business objectives change, the Foundation Model adapts to keep pace, ensuring your AI investment delivers long-term value. This futureproofing empowers you to remain competitive and innovative in a rapidly changing landscape.",
    },
  ],

  whyChooseUs: {
    title: "Why choose us",
    tagline: "It's more than just these reasons",
    content: [
      {
        id: "1",
        title: "Custom AI for unique business needs",
        tagline:
          "Tailor your AI to deliver precision and value where it matters most",
        icon: <FaBrain size={20} />,
      },
      {
        id: "2",
        title: "Accurate, data-driven AI",
        tagline:
          "Grounded in real-time data for reliable, context-aware decisions",
        icon: <SiTarget size={20} />,
      },
      {
        id: "3",
        title: "AI security you can trust",
        tagline: "Protect your AI from threats with robust, ethical safeguards",
        icon: <MdOutlineSecurity size={20} />,
      },
      {
        id: "4",
        title: "Scalable and future-ready",
        tagline: "Evolve with AI that grows with your business needs",
        icon: <FaExpandArrowsAlt size={20} />,
      },
    ],
  },

  FAQ: [
    {
      id: "1",
      question:
        "What is a Foundation Model, and how does it differ from traditional AI models?",
      answer:
        "A Foundation Model is a large-scale AI model trained on diverse data that serves as a base for various applications. Unlike traditional models, which are often built for specific tasks, Foundation Models are versatile and can be fine-tuned for multiple use cases, providing greater flexibility and adaptability.",
    },
    {
      id: "2",
      question:
        "How can a Foundation Model be customized to meet my organization’s specific needs?",
      answer:
        "Foundation Models can be tailored through fine-tuning processes that adjust the model to your business requirements. This customization ensures that the AI understands your specific context, language, and industry, enabling it to deliver more relevant and accurate outputs.",
    },
    {
      id: "3",
      question:
        "What are the key benefits of using a Foundation Model in our business operations?",
      answer:
        "Key benefits include improved efficiency through automation, enhanced accuracy in data-driven decisions, personalized customer interactions, and reduced operational risks. These advantages contribute to better overall performance and competitive positioning.",
    },
    {
      id: "4",
      question:
        "How does the Foundation Model ensure accuracy and reliability in AI interactions?",
      answer:
        "The Foundation Model employs advanced training techniques and continuous learning from relevant data, which helps improve its accuracy over time. Regular updates and fine-tuning processes also enhance reliability and minimize errors.",
    },
  ],

  meta: {
    title: "AI Foundation Model | Choose the Right Model with iNextLabs",
    description:
      "Select the best AI foundation model for your business with iNextLabs. Get expert guidance, fine-tune for accuracy, and protect your AI with advanced security.",
    locale: "en_US",
    siteName: "iNextLabs",
    image:
      "https://www.inextlabs.com/images/products/future-proof-ai/foundation-model-meta.jpg",
    type: "website",
  },
};
