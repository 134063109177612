import React from "react";
import Meta from "../../Meta";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import ProductsBanner from "../banner/ProductsBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProps from "../../swimlinesection/ValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import AccordionSection from "../../accordion/Accordion";
import Footer from "../../footer/Footer";
import { omnichannelContent } from "./onmichannelContent";

const Omnichannel = () => {
  return (
    <div>
      <Meta
        title={omnichannelContent.meta.title}
        description={omnichannelContent.meta.description}
        image={omnichannelContent.meta.image}
        locale={omnichannelContent.meta.locale}
        siteName={omnichannelContent.meta.siteName}
        type={omnichannelContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <ProductsBanner content={omnichannelContent.banner} />
      <CounterStats content={omnichannelContent.stats} />
      <ValueProps content={omnichannelContent.valueProps} />
      <ValueProposition content={omnichannelContent.whyChooseUs} />
      <DiscoveryWorkshop />
      <AccordionSection content={omnichannelContent.FAQ} />
      <Footer />
    </div>
  );
};

export default Omnichannel;
