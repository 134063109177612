import React from "react";
import Divider from "../../../Divider";

const Locations = (props) => {
  const locationContent = props.content;
  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <h2 className="about-location-section-title sectionTitle">
            Our Location
          </h2>
          <Divider />
          <div className="about-location-container">
            <div className="about-location-container-image">
              <img src="/images/company/map.svg" alt="location" />
            </div>
            <div className="about-location-container-list">
              {locationContent.map((location, index) => (
                <div className="about-location-container-list-card" key={index}>
                  <h4 className="about-location-container-list-card-title paragraphTitle">
                    {location.city}
                  </h4>
                  <div className="about-location-container-list-card-address para">
                    <p>{location.address}</p>
                    <p>{location.phoneNumber}</p>
                  </div>
                  
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Locations;
