export const contactContent = {
  banner: {
    button: {
      text: "Start Your Free Trial",
      url: "/sign-up",
    },
    description: "Empowering Your Success with Support and Solutions.",
    id: "1",
    imageURL: "/images/banner-images/careers.png",
    sectionTile: "",
    title: "Kindly share with us how we can be of help to you",
    videoURL:
      "https://inextlabswebassets.blob.core.windows.net/video/he6c692b8_230725_01_London%20Businesswomen_4k_018.mp4",
  },
  support: [
    {
      id: "1",
      title: "Product Advice",
      content:
        "Considering our product? Require technical guidance to determine the optimal solution for your needs?",
      link: "Contact Sales",
    },
    {
      id: "2",
      title: "24/7 Chat support",
      content:
        "Please log in to our web interface and create a ticket. Our support team will contact you shortly.",
      link: "Talk to Support",
    },
    {
      id: "3",
      title: "Request Feature",
      content:
        "Do you miss anything in our product? Share your suggestions by using the Feature Request from our portal.",
      link: "Request a feature",
    },
  ],
  meta: {
    title:
      "Contact iNextLabs | Get in Touch",
    description:
      "Reach out to Inext Labs for inquiries, collaborations, or support. Contact us today to explore how we can help drive your AI initiatives forward.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
