import React from "react";
import Divider from "../Divider";

const PartnersLogo = (props) => {
  const images = props.content;
  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <h2 className="partners-logo-section-title">Strong Together-United Partners in the Lead!</h2>
          <Divider/>
          <div className="partners-logo-section logogrid">
            {images.map((imageUrl, index) => (
              <div className="logogrid__item" key={index}>
                <img src={imageUrl} alt="partner logo" className="logogrid__img" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnersLogo;
