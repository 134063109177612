import React, { useState } from "react";
import SignUpModal from "../../modal/SignUpModal";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Button } from "@material-tailwind/react";
import { freeTrailClickFunction } from "../../../app-insights/freeTrailClickFunction";

const CompanyBanner = (props) => {
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen

  return (
    <div>
      <div className="bg-white company-banner-outer-container">
        <div className="company-banner-inner-container">
          <video id="company-banner-background-video" muted autoPlay loop>
            <source src={props.content.videoURL} />
          </video>
          <div className="company-banner-content-wrapper-outer">
            <div className="company-banner-content-wrapper">
              <div className="company-banner-content">
                <h1 className="company-banner-content-title">
                  {props.content.title}
                </h1>
                <p className="company-banner-content-text">
                  {props.content.description}
                </p>
                <div className="company-banner-cta-buttons">
                  {props.content?.model !== "partners" &&
                  props.content?.model !== "career" ? (
                    <div className="company-banner-cta-button">
                      <Button
                        className="company-banner-cta-button-1 button"
                        onClick={() => {
                          setIsOpen(true);
                          freeTrailClickFunction();
                        }}
                      >
                        {props.content.button.text}
                      </Button>
                      <p className="credit-card-text">
                        *No credit card required
                      </p>
                    </div>
                  ) : (
                    <>
                      {props.content.model === "partners" ? (
                        <HashLink smooth to="/partners/#partnerform">
                          <Button className="company-banner-cta-button-1 button">
                            {props.content.button.text}
                          </Button>
                        </HashLink>
                      ) : (
                        <Link to={props.content.button.url}>
                          <Button className="company-banner-cta-button-1 button">
                            {props.content.button.text}
                          </Button>
                        </Link>
                      )}
                    </>
                  )}
                </div>
                {/* {props.content?.model === "career" && (
                  <div className="company-banner-content-privacy-policy-section">
                    <p className="para">
                      By clicking the “Join our Global Team” button, you are
                      accepting the{" "}
                      <span>
                        <a href="/privacy-policy">privacy policy</a>
                      </span>
                      .
                    </p>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default CompanyBanner;
