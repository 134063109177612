import React from "react";
import Divider from "../Divider";
import { infoTableContent } from "./infoTableContent";
import { HashLink } from "react-router-hash-link";
import { Button } from "@material-tailwind/react";

const InfoTable = (props) => {
  return (
    <>
      <div className="outerContainer info-table-outer-container">
        <div className="innerContainer">
          <div className="info-table-container">
            <div className="info-table-title">
              <h2 className="sectionTitle">Use-cases and success stories</h2>
            </div>
            <Divider />
            <div className="info-table-body-container">
              {infoTableContent.infoTable.map((info, index) => (
                <div
                  className={`info-table-body-inner-container ${
                    index === 1 ? "reverse-column" : ""
                  }`}
                >
                  <div className="info-table-body-icon">
                    <img src={info.imageURL} alt="icon" />
                  </div>
                  <div className="info-table-body">
                    <div className="info-table-body-title">
                      <h3 className="paragraphTitle">{info.title}</h3>
                    </div>
                    <div className="info-table-body-text para">
                      <p>{info.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="info-table-footer">
              <p className="para">
                Join our Discovery Workshop to learn how tailored solutions can
                enhance your operations, improve customer interactions, and
                empower your research capabilities. Take the leap into
                innovation
              </p>
              <HashLink smooth to="/contact/#contactform">
                <Button className="button bannerPrimaryButton">
                  Contact Us Today
                </Button>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoTable;
