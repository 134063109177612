import React from "react";
import Header from "../../header/Header";
import Filler from "../../header/Filler";

import HomeBanner from "../../banner/HomeBanner";
import Footer from "../../footer/Footer";
import Teams from "./teams/Teams";
import { aboutContent } from "./aboutContent";
import AboutStatement from "./about-statement/AboutStatement";
import AboutCoreValues from "./core-value/AboutCoreValues";
import Locations from "./location/Locations";
import Meta from "../../Meta";
import CompanyBanner from "../banner/CompanyBanner";
import PartnersLogo from "../../partners-logo/PartnersLogo";

function About() {
  return (
    <>
      <Meta
        title={aboutContent.meta.title}
        description={aboutContent.meta.description}
        image={aboutContent.meta.image}
        locale={aboutContent.meta.locale}
        siteName={aboutContent.meta.siteName}
        type={aboutContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <CompanyBanner content={aboutContent.banner} />
      <AboutStatement />
      <Teams content={aboutContent.teams} />
      <PartnersLogo content={aboutContent.partners} />
      <AboutCoreValues content={aboutContent.coreValues} />
      <Locations content={aboutContent.locations} />
      <Footer />
    </>
  );
}

export default About;
