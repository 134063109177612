import React from "react";
import { newsContent } from "./newsContent";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import Footer from "../../footer/Footer";
import Meta from "../../Meta";
import NewsItems from "./NewsItems";
import CompanyBanner from "../banner/CompanyBanner";

const News = () => {
  return (
    <>
      <Meta
        title={newsContent.meta.title}
        description={newsContent.meta.description}
        image={newsContent.meta.image}
        locale={newsContent.meta.locale}
        siteName={newsContent.meta.siteName}
        type={newsContent.meta.type}
        noindex={false}
      />

      <Header />
      <Filler />
      {/* <HomeBanner content={newsContent.banner} /> */}
      <CompanyBanner content={newsContent.banner} />
      <NewsItems content={newsContent.newsItems} />
      <Footer />
    </>
  );
};

export default News;
