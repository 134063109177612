import React, { useEffect, useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import Divider from "../../Divider";
import JobDetails from "./JobDetails";

const CareerDetails = (props) => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [sortedCareerContent, setSortedCareerContent] = useState([]);

  const CareerContent = props.content;

  useEffect(() => {
    const sortedContent = [...CareerContent].sort((a, b) => b.jobID - a.jobID);
    setSortedCareerContent(sortedContent);
  }, [CareerContent]);

  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <h2 className="career-section-Title sectionTitle">Open Positions</h2>
          <Divider />
          <div className="career-container">
            <div className="career-container-info">
              <div className="career-container-info-description para">
                <p>
                  Unlock your potential with us! Join our dynamic team of
                  innovators, where your ideas shape the future of customer
                  satisfaction. We're hiring, be part of the transformation!
                </p>
              </div>
            </div>
            <div className="career-job-list-container">
              <div className="career-job-list-header">
                <div>Title</div> <div>Department</div>
                <div>Location</div> <div></div>
              </div>
              {sortedCareerContent.map((career, index) => (
                <div className="career-job-list-card" key={index}>
                  <div className="career-job-list-title paragraphTitle">
                    {career.jobTitle}
                  </div>
                  <div className="career-job-list-job-type">
                    {career.department}
                  </div>
                  <div className="career-job-list-job-type">
                    {career.joblocation}
                  </div>
                  <div className="career-job-list-job-button">
                    <button
                      type="submit"
                      onClick={() => setSelectedJob(career)}
                    >
                      Apply Now
                      <FiArrowRight />
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <JobDetails
              job={selectedJob}
              onClose={() => setSelectedJob(null)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerDetails;
